@import '../../../scss/settings';

.TemplateCategory {
    .detail-section,
    .Slice {
        .content {
            max-width: 1260px;
            padding: 0 60px;
        }

        &:before {
            display: block;
            padding-bottom: 50px;
            content: '';
        }

        &:after {
            display: block;
            margin: 0 auto;
            padding-top: 50px;
            content: '';
            width: 100px;
            border-bottom: 1px solid #d8d8d8;
        }

        &:last-child:after {
            border: 0;
        }
    }

    .detail-section {
        padding-top: 64px;

        .Breadcrumb .BreadcrumbEntry {
            a {
                color: #808080;
            }

            &.inactive a {
                color: $black;
            }
        }

        &:before {
            display: none;
        }
    }

    .PageTitle,
    .PageIntroduction,
    .PageTitle {
        padding-left: 0;
        padding-right: 0;
    }

    .PageTitle {
        max-width: 945px;
        margin: 0 auto;
    }

    .PageIntroduction {
        max-width: 790px;

        color: $dark-grey;
        font-size: 22px;
        line-height: 30px;
        text-align: center;

        ol,
        ul,
        p,
        ol li,
        ul li {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .detail-section {
        .PageIntroduction {
            margin: 10px auto 40px auto;
            padding-bottom: 0;
        }
    }

    .LargeTemplatePreviewSlice {
        .content {
            margin-bottom: 60px;
        }
    }

    &.image-header {
        .Header {
            position: absolute;
            background: none;
        }

        .Section.detail-section {
            background: $charcoal;
            background-size: cover;

            &:before {
                display: block;
                content: '';
                width: 100%;
                height: 100px;
            }

            .PageTitle,
            .PageIntroduction {
                color: $white;
            }

            &:after {
                margin-top: 0;
                padding-top: 70px;
                border: 0;
            }
        }
    }

    @media (max-width: $break_desktop) {
        .PageTitle {
            max-width: 790px;
        }
    }

    @media (max-width: $break_laptop) {
        .detail-section {
            padding-top: 10px;
        }
    }

    @media (max-width: $break_mobile) {
        .Section {
            padding-right: $mobile-horizontal-padding;
            padding-left: $mobile-horizontal-padding;
        }

        .Header {
            margin: 0;
        }

        .PageTitle {
            padding: 20px 0 14px 0;

            font-size: 34px;
            text-align: left;
        }

        .PageIntroduction {
            padding: 0;

            font-size: 20px;
            text-align: left;
        }

        .detail-section {
            .PageIntroduction {
                margin: 10px 0 30px 0;
            }
        }

        .detail-section,
        .Slice {
            .content {
                padding: 0;
            }

            &:before {
                padding-bottom: 40px;
            }

            &:after {
                padding-top: 40px;
            }

            &.detail-section {
                &:before {
                    padding-bottom: 30px;
                }
            }
        }

        .LargeTemplatePreviewSlice {
            .content {
                margin-bottom: 10px;
            }
        }

        .Section.RegisterFormFooterSection {
            margin: 0 $mobile-horizontal-padding;
            padding: 0;

            .panel {
                padding: 0 0 25px 0;
            }

            &:before {
                display: block;
                padding-bottom: 40px;
                border-top: 1px solid #d8d8d8;
                width: 100%;
                content: '';
                background: white;
            }

            &:after {
                content: none;
            }
        }
    }

    &.image-header .detail-section:before {
        height: 70px;
    }
}
