// Fonts
$font-weight-semibold: 600;

$default-body-font-size: 30px;
$font-size-1: 40px;
$font-size-2: 30px;
$font-size-3: 24px;
$font-size-4: 20px;
$font-size-5: 16px;

$font-basic: 'Source Sans Fallback - Helvetica', 'Source Sans Fallback - Arial', sans-serif;
$font-body: Source Sans, $font-basic;
$font-title: Tiempos, 'Tiempos Fallback';

$font-blog-title: 'Domaine Display Narrow', Times, serif;
$font-blog-body: 'Meta Serif OT', Times, serif;

$font-body-alt: Inter, Source Sans, $font-basic;

$font-monospace: 'Source Code Pro', 'Menlo', 'Courier New', Courier, monospace;
$font-icon: Gotham, $font-basic;
