@import '../../../scss/settings';

.LegalPage {
    background: $charcoal;

    .title-section {
        padding-bottom: 20px;
    }

    .PageTitle {
        color: $white;
        font-weight: bold;
    }

    .page-content {
        padding: 0 20px;

        ul {
            margin: 1rem 0 15px 0;
            padding: 0;

            > li {
                display: table;
                margin-bottom: 0.6em;
            }

            > li:before {
                display: table-cell;
                padding-right: 0.6em;
                content: '•';
            }
        }

        ol {
            margin: 5px 0 15px 0;
            padding: 0;
            list-style-type: none;
            counter-reset: item;

            > li {
                display: table;
                margin-bottom: 0.6em;
                counter-increment: item;
            }

            > li:before {
                display: table-cell;
                padding-right: 0.6em;
                content: counters(item, '.') '. ';
            }

            ol {
                margin-top: 0;

                > li {
                    margin: 10px 0 0 0;
                }

                > li:before {
                    content: counters(item, '.') ' ';
                }

                ol {
                    > li:before {
                        content: '(' counter(item, lower-latin) ')';
                    }

                    ol {
                        > li:before {
                            content: counter(item, lower-roman) '.';
                        }
                    }
                }
            }
        }

        .list-style-latin {
            ol {
                > li:before {
                    content: '(' counter(item, lower-latin) ')';
                }

                ol {
                    > li:before {
                        content: counter(item, lower-roman) '.';
                    }
                }
            }
        }
    }

    @media (max-width: $break_tablet) {
        padding: 0 0 50px 0;

        .image {
            display: none;
        }
    }

    @media (max-width: $break_mobile) {
        padding: 0 0 10px 0;

        .page-content {
            padding: 0 10px;
        }

        .title-section {
            padding-top: 0;
            padding-bottom: 10px;
        }

        // From here down - mobile styling to prevent the text from overflowing the window
        .TextContent ol li,
        .TextContent li li {
            padding-left: 0;
        }

        .TextContent h3 {
            display: inline;
        }

        .page-content ul > li,
        .page-content ol > li {
            display: block;
        }

        .page-content ul > li:before,
        .page-content ol > li:before {
            display: inline;
        }
    }
}
