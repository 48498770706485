@import '../../../scss/settings';

.RegisterCallToActionFooterSection {
    margin-top: 9rem;
    margin-bottom: 15rem;

    &.AsideSection {
        .content {
            background: #f3f3f4;
            border-radius: 8px;

            section {
                width: 360px + 97px;
                flex-basis: 360px + 97px;

                padding-left: 9.7rem;

                .section-content {
                    h2 {
                        margin-bottom: 1rem;

                        font-family: $font-title;
                        font-weight: 600;
                        font-size: 3rem;
                    }

                    .call-to-action {
                        margin-bottom: 2rem;
                        font-size: 2rem;
                        line-height: 1.4;
                    }

                    .signup-button {
                        margin-bottom: 2rem;
                    }

                    .signup-line {
                        color: #808080;
                        font-size: 1.4rem;
                    }
                }
            }

            aside {
                height: 450px;
                padding-right: 97px;

                img {
                    width: 555px;
                    height: auto;
                    box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    @media (max-width: $break_desktop) {
        &.right .content {
            section {
                margin-left: 0;
            }
        }
    }

    @media (max-width: $break_laptop) {
        &.AsideSection.right .content {
            section {
                text-align: left;
                padding-left: 9.7rem;
                margin-left: 0;

                .section-content {
                    h2 {
                        font-size: 2.4rem;
                    }

                    .call-to-action {
                        font-size: 1.8rem;
                        line-height: 1.6;
                    }
                }
            }
        }
    }

    @media (max-width: $break_tablet) {
        margin-left: 2.5rem;
        margin-right: 2.5rem;

        &.AsideSection.right .content {
            display: flex;
            flex-direction: row-reverse;

            aside {
                padding-right: 4rem;
            }

            section {
                text-align: left;
                padding-left: 4rem;
                padding-right: 0;
                margin-left: 0;
            }
        }
    }

    @media (max-width: $break_mobile) {
        margin-top: 3.3rem;
        margin-bottom: 6rem;

        &.AsideSection.right {
            padding: 0;

            > .content {
                padding: 2rem 2rem 3rem 2rem;
                display: block;

                aside {
                    width: auto;
                    height: auto;

                    flex-basis: auto;

                    padding: 0;

                    margin-bottom: 1.5rem;
                }

                section {
                    width: auto;
                    flex-basis: auto;
                    padding: 0;

                    .section-content {
                        h2 {
                            font-size: 2rem;
                        }

                        .call-to-action {
                            font-size: 1.8rem;
                        }

                        .signup-button {
                            margin-bottom: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}
