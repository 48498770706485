@import '../../../scss/settings';

.AppDownload.WebClipperDownload {
    .AsideSection.left {
        padding-top: 24px;

        .content section .section-content {
            margin-top: -35px;
        }

        aside {
            width: 70%;
            margin-left: -135px;
            margin-right: 19px;
            padding-right: 56px;
        }
    }

    .download-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 64px;

        padding: 10px 24px;
        margin-bottom: 10px;

        border-radius: 6px;
        border: none;
        background-color: $mid-dark-grey;

        &:last-of-type {
            margin-bottom: 0;
        }

        .link-text {
            font-size: 18px;
            color: $brand-color;

            &:after {
                display: inline-block;
                content: '';

                width: 6px;
                height: 10px;

                margin-left: 10px;

                background: url('./download-link-chevron.svg');
            }
        }

        img {
            width: 40px;
            height: 40px;

            margin-right: 20px;
        }
    }

    @media (max-width: $break_desktop) {
        .AsideSection.left {
            .content {
                aside {
                    padding-left: 20px;
                    min-width: 0;
                }
            }
        }
    }

    @media (max-width: $break_tablet) {
        .AsideSection.left .content {
            flex-direction: column-reverse;
        }

        .PageTitle {
            padding: 0;
            text-align: center;
        }

        .PageIntroduction {
            text-align: center;
        }

        .AsideSection.left {
            padding-top: 42px;

            .content {
                section {
                    width: auto;

                    .section-content {
                        margin-top: 0;
                    }
                }

                aside {
                    width: auto;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    @media (max-width: $break_mobile) {
        .AsideSection.left {
            padding-top: 75px;
            margin-top: -75px;
            .content {
                section {
                    .section-content {
                        width: auto;
                    }
                }
            }
        }
    }
}
