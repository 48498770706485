@import '../../../scss/settings';

.SuperGuideHeader {
    margin-top: -100px;
    padding-top: 100px;

    height: 670px;

    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $charcoal;

    > .content {
        margin: 0 auto 0 auto;
        max-width: 840px;
    }

    &.no-navigation {
        height: auto;

        > .content {
            padding-bottom: 120px;
        }
    }

    @media (max-width: $max-page-width) {
        background-size: auto 100%;
    }

    @media (max-width: $break_tablet) {
        height: auto;

        > .content {
            padding-top: 40px;
            padding-bottom: 120px;

            .PageTitle {
                max-width: 60%;
            }
        }
    }

    @media (max-width: $break_mobile) {
        > .content {
            padding-bottom: 100px;

            .PageTitle {
                max-width: none;
            }
        }
    }
}
