@import '../../../scss/settings';

.Breadcrumb {
    display: block;
    text-align: center;

    .BreadcrumbEntry {
        display: inline;

        a {
            font-weight: $font-weight-semibold;
            font-size: 14px;
            letter-spacing: 4px;
            text-transform: uppercase;
            text-decoration: none;
        }

        &.inactive a {
            color: $charcoal;
        }

        &:after {
            margin: 0 10px;
            content: '/';
            color: $mid-grey;
        }

        &:last-child:after {
            content: none;
        }
    }

    &.on-dark-background {
        .BreadcrumbEntry.inactive a {
            color: $white;
        }
    }

    @media (max-width: $break_mobile) {
        .BreadcrumbEntry {
            a {
                letter-spacing: 1px;
            }

            &:first-child {
                display: none;
            }
        }
    }
}
