@import '../../../scss/settings';

.BlogHeader {
    height: 100px;
    width: 100%;

    #blog-header-spacer {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        height: 100px;
        width: 100%;

        .BlogPost & {
            background-color: $blog-brand-color;
        }
    }

    #blog-header-floating {
        display: flex;
        align-items: center;
        justify-content: center;

        position: fixed;
        top: 0;
        left: 0;
        right: 0;

        height: 100px;

        background-color: $charcoal;

        z-index: 1100;

        transition: height 200ms linear;

        &.shrink {
            height: 60px;

            .SignUpButton {
                transform: scale(0.9);
            }
        }
    }

    .blog-header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        max-width: 1044px;

        margin-left: 30px;
        margin-right: 30px;

        .header-left {
            display: flex;
            align-items: center;
        }
    }

    .logo {
        width: 134px;
        height: 36px;

        margin-top: 2px;
    }

    .motto {
        margin-left: 40px;
        color: $blog-header-motto-color;
    }

    .SignUpButton {
        white-space: nowrap;

        transition: transform 200ms linear;
    }

    @media (max-width: $break_mobile) {
        .header-left {
            justify-content: space-between;
            width: 100%;

            .motto {
                text-align: center;
            }
        }

        .header-right {
            display: none;
        }
    }

    @media (max-width: 400px) {
        .motto {
            display: none;
        }
    }
}
