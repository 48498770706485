@import '../../../scss/settings';

.Guide2020Page {
    // Laptop only
    @media (min-width: $break_tablet + 1) and (max-width: $break_laptop) {
        .Section > .content,
        .GridSection > .content {
            margin-left: auto;
            margin-right: auto;
            width: 1010px;
        }
    }

    // Tablet only
    @media (min-width: $break_mobile + 1) and (max-width: $break_tablet) {
        .Header > .header-content {
            padding-left: 25px;
            padding-right: 25px;
        }

        .Section > .content,
        .GridSection > .content {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
