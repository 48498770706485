@import '../../../scss/settings';

.GuideLanding {
    background: $charcoal;

    .PageTitle {
        text-align: center;
        color: $white;
        padding: 35px 0 70px 0;

        @media (max-width: $break_tablet) {
            padding: 29px 0 61px 0;
        }

        @media (max-width: $break_mobile) {
            padding: 6px 0 12px 0;
        }
    }
}
