@import '../../../scss/settings';

.InspirationFeatureSection {
    position: relative;

    .PageIntroduction {
        display: flex;
        justify-content: center;

        color: $dark-grey;

        .description-container {
            max-width: 900px;
        }
    }

    .background-image-container {
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;

        z-index: -1;

        .background-image {
            width: 870px;
        }
    }

    .mobile-background-image {
        display: none;
    }

    .InspirationDetailsSection {
        .desktop-title {
            font-size: $font-size-3;
        }

        p {
            font-size: 21px;
        }
    }

    // Tablet and larger
    @media (min-width: $break_tablet + 1) {
        .InspirationDetailsSection {
            .content {
                padding-top: 50px;
                padding-bottom: 80px;
            }
        }
    }

    // Desktop and larger
    @media (min-width: $break_desktop + 1) {
        .PageIntroduction,
        .PageTitle {
            padding-bottom: 25px;
        }

        .InspirationDetailsSection {
            .content {
                padding-top: 70px;
                padding-bottom: 110px;
            }
        }
    }

    // Desktop & smaller
    @media (max-width: $break_desktop) {
        .PageIntroduction {
            .description-container {
                max-width: 750px;
            }
        }

        .InspirationDetailsSection {
            .desktop-title {
                font-size: 21px;
            }

            p {
                font-size: 18px;
            }
        }
    }

    // Tablet & smaller
    @media (max-width: $break_tablet) {
        position: initial;

        .background-image-container {
            display: none;
        }

        .mobile-background-image {
            display: block;
            position: absolute;
            top: 0;
            width: 100%;

            z-index: -1;
        }

        .PageTitle {
            font-size: 24px;
        }

        .InspirationDetailsSection {
            .content {
                border-top: 1px solid $plan-divider-color;
                border-bottom: 1px solid $plan-divider-color;
            }
        }
    }

    // Mobile
    @media (max-width: $break_mobile) {
        position: relative;
    }
}
