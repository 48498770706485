@import '../../../scss/settings';

.MilanoteLogo {
    display: flex;
    flex-direction: row;
    align-items: center;

    .milanote-name {
        margin-left: 8px;
        font-family: $font-title;
        font-size: 22px;
        color: $header-brand-name-color;
    }

    &.white,
    &.light {
        .milanote-name {
            color: $white;
        }

        path {
            fill: $white;
        }
    }

    &.center {
        flex-direction: column;
        justify-content: center;

        .milanote-name {
            margin-left: 0;
            margin-top: 5px;
        }
    }

    @media (min-width: $break_mobile + 1) {
        &.light-mobile {
            .milanote-name {
                color: $charcoal;
            }

            path {
                fill: $charcoal !important;
            }
        }
    }

    @media (max-width: $break_mobile) {
        &.light-mobile {
            .milanote-name {
                color: $white;
            }

            path {
                fill: $white;
            }
        }
    }
}
