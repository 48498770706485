@import '../../../scss/settings';

.TemplateListingPage {
    height: 100%;
    background-color: $charcoal;

    @media (max-width: $break_mobile) {
        padding: 0;
    }

    .content-container {
        display: flex;
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 30px 33px 30px;

        .grid-container {
            margin-right: -15px;
            width: 100%;

            .PageTitle {
                text-align: center;
                color: $white;
                font-weight: $font-weight-semibold;
                padding: 35px 0 70px 0;

                @media (max-width: $break_tablet) {
                    padding: 29px 0 61px 0;
                }

                @media (max-width: $break_mobile) {
                    padding: 6px 0 12px 0;
                }
            }

            .no-result-message {
                font-family: $font-body;
                font-size: 21px;
                line-height: 1.43;
                color: #b5b5b5;
                padding-left: 15px;
                padding-top: 10px;
            }
        }

        @media (max-width: $break_mobile) {
            padding: 15px 5px 33px 5px;
            flex-direction: column-reverse;
        }
    }

    .template-listing {
        margin-bottom: 20px;

        .GridItem {
            margin: 15px;

            @media (max-width: $break_mobile) {
                margin: 10px 15px;
            }
        }

        & > .content {
            padding: 0;
        }

        @media (max-width: $break_tablet) {
            min-height: 430px;
        }

        .TemplateListingEntry.GridItem {
            @media (max-width: $break_tablet) {
                width: calc(50% - 30px);
            }

            @media (max-width: 580px) {
                width: calc(100% - 30px);
            }

            @media (max-width: 400px) {
                width: calc(100% - 30px);
            }
        }
    }
}
