@import '../../../scss/settings';

.MilanoteAd {
    @media (min-width: $break_tablet + 1) {
        margin-left: -100px;
        width: 946px;

        margin-top: 70px;
        margin-bottom: 35px;
    }

    @media (max-width: $break_tablet) {
        margin-top: 60px;
        margin-bottom: 30px;
    }

    @media (max-width: $break_mobile) {
        margin-top: 20px;
        margin-bottom: 17px;
    }

    .AsideSection.right {
        max-width: 946px;
        max-height: 430px;
        margin: 0 auto;

        background: $charcoal;

        > .content {
            > aside {
                padding: 0;
                max-height: 430px;
                width: 50%;
            }

            section {
                width: 50%;
                flex-basis: auto;
                margin-left: 0;
                padding: 20px 56px;

                text-align: left;
            }
        }

        @media (max-width: $break_tablet) {
            max-width: 750px;

            > .content {
                flex-direction: row;

                > aside {
                    width: 50%;
                    order: 2;
                }

                section {
                    width: 375px;
                    order: 1;
                    padding: 30px 36px 40px;
                }
            }
        }

        @media (max-width: $break_mobile) {
            max-height: none;

            > .content {
                flex-direction: column;

                > aside {
                    order: 2;
                    width: 100%;
                }

                section {
                    order: 1;
                    width: 100%;

                    text-align: center;
                }
            }
        }
    }

    .hero-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .Video {
            position: absolute;
            top: 50px;
            width: 145%;
        }

        @media (max-width: $break_tablet) {
            .Video {
                top: 30px;
                width: 540px;
            }
        }

        @media (max-width: $break_mobile) {
            height: 160px;
            padding-top: 20px;

            .Video {
                position: relative;
                top: 0;
                width: 100%;
                padding: 0 34px;
            }
        }
    }

    .logo {
        width: 134px;
        height: 36px;
    }

    .title {
        color: $white;
        font-size: $font-size-2;
        font-family: $font-title;

        padding: 0.8em 0 0.4em 0;

        @media (max-width: $break_tablet) {
            padding: 0.6em 0 0.4em 0;
            font-size: $font-size-3;
        }

        @media (max-width: $break_mobile) {
            font-size: 18px;
        }
    }

    .description {
        padding: 0 0 1.5em 0;

        color: rgba(255, 255, 255, 0.7);
        font-size: $font-size-3;
        line-height: 1.6;

        @media (max-width: $break_tablet) {
            font-size: $font-size-4;
            line-height: 1.4;
            padding: 0 0 16px 0;
        }

        @media (max-width: $break_mobile) {
            font-size: 16px;
        }
    }
}
