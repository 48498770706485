body {
    overflow-x: hidden;
}

.PageContainer {
    max-width: 1440px;
    margin: 0 auto;

    &.full-width {
        max-width: none;
    }
}
