@import '../../../scss/settings';

.TemplateCategoryAuthor {
    display: flex;
    flex-direction: column;
    align-items: center;

    .UserImage {
        width: 50px;
        height: 50px;

        margin-bottom: 10px;
    }

    .author-caption {
        color: $mid-grey;
        font-size: 14px;
    }
}
