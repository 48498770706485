@import '../../../scss/settings';

.Guide2020TableOfContents {
    width: 100%;
    margin-top: 1.1rem;

    font-size: 1.6rem;
    line-height: 1.5;

    color: #6e6e71;

    .guide-toc-header {
        width: 100%;

        padding-bottom: 3rem;
        margin-bottom: 3rem;

        border-bottom: 1px solid #e5e5e5;

        .UserImage {
            width: 50px;
            height: 50px;
            margin-bottom: 8px;
        }

        &.no-date {
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    .guide-toc-navigation {
        margin-bottom: 3rem;

        h4 {
            margin-bottom: 1.4rem;
            font-weight: bold;
        }

        ul {
            li {
                display: flex;
                margin-bottom: 6px;

                a {
                    display: flex;

                    color: #909090;
                    text-decoration: none;

                    .step-number {
                        margin-right: 5px;
                    }

                    &.active {
                        color: $charcoal;
                    }

                    &:hover {
                        color: $charcoal;
                    }
                }
            }
        }
    }

    .guide-toc-share-buttons {
        h4 {
            margin-bottom: 1.4rem;
            font-weight: bold;
        }
    }

    @media (max-width: $break_laptop) {
        .guide-toc-header {
            padding-bottom: 2rem;
            margin-bottom: 1rem;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            .UserImage {
                margin-top: 0.5rem;
                margin-right: 1rem;

                width: 4rem;
                height: 4rem;
            }
        }

        .guide-toc-navigation {
            margin-bottom: 2rem;

            h4 {
                margin-bottom: 0.6rem;
            }

            ul {
                li {
                    margin-bottom: 3px;
                }
            }
        }

        .guide-toc-share-buttons {
            h4 {
                margin-bottom: 1.4rem;
            }
        }
    }

    @media (max-width: $break_mobile) {
        height: auto;
        width: 100%;

        .guide-toc-header {
            display: none;
        }

        .guide-toc-navigation {
            display: none;
        }

        .guide-toc-share-buttons {
            display: none;
        }
    }
}
