@import '../../../scss/settings';

.RegisterFormFooterSection {
    position: relative;
    z-index: 1;

    .background {
        display: flex;
        flex-direction: column;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: -webkit-linear-gradient(top, #ffffff 10%, #d7d7d7 85%);

        z-index: -1;

        .background-image {
            flex-grow: 1;
            overflow: hidden;
            display: flex;
            justify-content: center;

            img {
                width: 2000px;
            }
        }

        .background-footer {
            flex-grow: 0;
            flex-shrink: 0;

            background: $charcoal;
            height: 120px;
        }

        .background-circle-container {
            position: absolute;
            bottom: 73px;

            width: 100%;
        }

        .background-circle {
            width: 100px;
            margin: 0 auto;

            transform: translateX(240px);
        }
    }

    > .content {
        max-width: 520px;
        padding: 64px 0;

        text-align: center;

        h2 {
            margin-bottom: 0.5em;

            color: $charcoal;
            font-family: $font-title;
            font-size: $font-size-2;
            font-weight: 600;
            line-height: 1.3;
        }

        p {
            color: #818181;
            font-size: 21px;
            line-height: 1.3;
        }
    }

    @media (max-width: $break_tablet) {
        .FloatingPanel {
            width: auto;
        }
    }

    @media (max-width: $break_mobile) {
        background: none;

        .FloatingPanel > .panel {
            border: none;
            border-radius: 0;

            box-shadow: none;
        }

        > .content {
            max-width: none;
            padding: 0;

            h2 {
                font-size: $font-size-3;
            }
        }

        .background-circle-container {
            display: none;
        }
    }
}

@media (min-width: 1688px) {
    .PageContainer.full-width {
        .RegisterFormFooterSection {
            .background {
                .background-image {
                    background-size: contain;
                }
            }
        }
    }
}
