@import '../../../scss/settings';

.Author {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 20px 0;

    .UserName {
        margin-left: 20px;

        font-size: 16px;
        line-height: 20px;
        color: #6f6f72;
    }
}
