@import '../../../scss/settings';

.UserImage {
    flex-shrink: 0;

    width: 60px;
    height: 60px;

    overflow: hidden;

    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
    }
}
