@import '../../../scss/settings';

.GuideSummaryAuthor {
    display: flex;
    font-size: 14px;
    line-height: 14px;

    .image {
        flex-shrink: 0;
        flex-grow: 0;
    }

    .detail {
        flex-grow: 1;
        flex-shrink: 1;

        margin-left: 15px;
    }

    .UserImage {
        width: 50px;
        height: 50px;
    }

    .name {
        font-weight: $font-weight-semibold;
        margin-top: 6px;
        margin-bottom: 4px;
    }
}
