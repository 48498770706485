@import '../../../../../scss/settings';

.TestimonalSlice {
    background-color: $light-grey;

    padding-top: $section_spacing - 15px;
    padding-bottom: $section_spacing - 15px;

    .Testimonial {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;

        padding: 27px 30px 35px 30px;

        border-radius: 6px;

        background: $white;

        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);

        .quote {
            min-height: 77px;

            color: $charcoal;
            font-family: $font-title;
            font-size: 17px;
            line-height: 1.5;
        }

        .image {
            margin-bottom: 15px;
        }

        .user {
            margin-top: auto;
        }

        .UserName {
            .company {
                margin-top: 25px;
            }
        }
    }

    @media (max-width: $break_tablet) {
        padding-top: 75px;
        padding-bottom: 75px;

        .Testimonial {
            .quote {
                min-height: 64px;

                font-size: 14px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        padding-top: 50px;
        padding-bottom: 50px;

        .Testimonial {
            margin: 10px;
            width: auto;

            .quote {
                min-height: 64px;

                font-size: 14px;
            }
        }

        .GridSection > .content {
            display: block;
            max-width: none;
            padding: 0;

            .GridItem {
                padding: 0;
                margin: 0;
            }
        }

        .swipe {
            overflow: hidden;
            visibility: hidden;
            position: relative;

            .swipe-wrap {
                overflow: hidden;
                position: relative;

                > div {
                    float: left;
                    width: 100%;
                    position: relative;
                }
            }
        }

        .swipe-markers {
            display: flex;

            align-items: center;
            justify-content: center;

            padding-top: 20px;

            > div {
                width: 7px;
                height: 7px;

                margin: 0 3px;
                border-radius: 50%;

                background: $mid-light-gray;

                &.active {
                    background: $charcoal;
                }
            }
        }
    }
}
