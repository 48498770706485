@import '../../../scss/settings';

.BlogEmailSubscription {
    text-align: center;
    padding: 60px 20px 60px 20px;
    background-color: $light-grey;

    .email-subscription-title {
        font-family: $font-blog-title;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.07;
        color: $charcoal;
        margin-bottom: 15px;
    }

    .email-subscription-description {
        font-family: $font-blog-body;
        font-size: 20px;
        line-height: 1.5;
        color: $secondary-button-color;
        margin-bottom: 33px;

        @media (max-width: 795px) {
            br {
                content: ' ';
            }

            br:after {
                content: ' ';
            }
        }
    }

    .email-subscription-input-container {
        display: flex;
        justify-content: center;
    }

    #subscribe-close-button {
        display: none;
    }

    @media (min-width: $break_tablet) {
        position: fixed;

        right: 48px;
        bottom: 48px;

        width: 410px;

        padding: 30px 30px 5px 30px;

        background: $white;

        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
        border-radius: 6px;

        opacity: 0;
        z-index: 1000;
        will-change: transform;
        transform: translate3d(0, 50px, 0);

        pointer-events: none;

        &.closed {
            display: none;
        }

        &.visible {
            pointer-events: auto;
            transform: translate3d(0, 0, 0);
            opacity: 1;
            transition: opacity 0.1s linear, transform 0.1s ease-out;
        }

        &.visible.enter {
            opacity: 0;
            transform: translate3d(0, 50px, 0);
        }

        &.visible.leave {
            opacity: 0;
            transition: opacity 0.1s linear 0.05s, transform 0.2s ease-in;
            transform: translate3d(0, 50px, 0);
        }

        .email-subscription-title {
            margin-bottom: 10px;
        }

        .email-subscription-description {
            display: block;
            font-family: $font-blog-body;

            font-size: 16px;
            line-height: 1.5;
            color: $secondary-button-color;
            margin-bottom: 16px;
        }

        .SubscriptionForm {
            .email-form {
                margin-bottom: 16px;
            }

            .FieldRow {
                margin-bottom: 0;
            }

            .TextField {
                input {
                    width: 200px;
                    border-color: $mid-light-gray;
                }

                &.error {
                    input {
                        border-color: $form-error-color;
                    }
                }
            }

            .submit {
                padding: 4px 16px 6px 16px;
            }

            .FormError {
                position: absolute;
            }

            .FieldRow .FormError.FieldError {
                display: none;
            }
        }

        .rss-json-description {
            font-size: 14px;
        }

        #subscribe-close-button {
            display: block;
            position: absolute;
            top: 0;
            right: 0;

            padding: 10px 10px 10px 20px;

            cursor: pointer;
        }
    }

    @media (max-width: $break_mobile) {
        padding: 40px 20px 40px 20px;

        .email-subscription-title {
            font-size: 24px;
            line-height: 1.25;
        }

        .email-subscription-description {
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 29px;
        }

        .email-subscription-input-container {
            flex-direction: column;
        }
    }
}
