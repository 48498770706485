@import '../../../scss/settings';

.Register {
    background-size: auto 100%;

    .title-section {
        padding-top: 75px;
        text-align: center;

        .PageTitle {
            font-weight: 600;
        }
    }

    .FloatingPanel .panel {
        max-width: 520px;
    }

    .register-section {
        padding-top: 20px;
        padding-bottom: 120px;

        text-align: center;

        h2 {
            font-family: $font-title;
            color: $charcoal;
            font-size: 24px;
            line-height: 30px;
        }
    }

    @media (max-width: 1375px) {
        background-size: auto 100%;
    }

    @media (max-width: $break_mobile) {
        background: $charcoal;
        background-image: none !important;

        .title-section {
            padding-top: 0;
        }

        .PageTitle {
            color: $white;
        }

        .register-section {
            padding: 0;
        }

        .MilanoteLogo {
            .milanote-name {
                color: $white;
            }

            .Logo g {
                stroke: $white;
            }
        }
    }
}
