@import '../../../scss/settings';

.FeaturePageHeader {
    position: relative;
    background: $charcoal;
    overflow: hidden;

    .AsideSection {
        position: relative;
        z-index: 1;
    }

    .PageTitle,
    .PageIntroduction {
        padding-left: 0;
    }

    .PageIntroduction {
        color: $mid-light-gray;
    }

    .spacer {
        width: 100%;
        padding-bottom: 93%;
    }

    .hero-container {
        width: 100%;
        max-width: 1170px;
        z-index: 0;

        > img {
            max-width: 100%;
            height: auto;
        }
    }

    // Tablet & desktop
    @media (min-width: $break_mobile + 1) {
        padding: 0 15px;

        .AsideSection > .content {
            > section {
                width: 490px;
                flex-basis: 490px;
                padding-left: 15px;

                .section-content {
                    padding-bottom: 120px;
                }

                @media (max-width: $break_desktop) {
                    margin-left: 0;
                }
            }

            > aside {
                flex-grow: 1;
                flex-shrink: 1;

                height: auto;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .hero-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            width: 100%;
            height: 100%;

            margin: 0 auto;

            transform: translate3d(135px, 0, 0);

            > img {
                position: absolute;
                right: 0;
                top: 0;
                max-width: 100%;
                padding-left: 30%;
            }
        }
    }

    // Tablet only
    @media (min-width: $break_mobile + 1) and (max-width: $break_tablet) {
        .AsideSection.left {
            > .content {
                flex-direction: row;
            }
        }

        .AsideSection.right {
            > .content {
                flex-direction: row-reverse;
            }
        }

        .AsideSection > .content {
            section {
                max-width: 400px;
                padding: 0 0 0 15px;

                .section-content {
                    text-align: left;
                }
            }

            aside {
                padding: 0;
            }
        }
    }

    @media (max-width: $break_tablet) {
        .PageTitle {
            font-size: 36px;
        }
    }

    // Mobile
    @media (max-width: $break_mobile) {
        .AsideSection {
            padding: 0 15px;

            > .content {
                > section {
                    padding: 0 15px;
                    text-align: left;

                    .section-content {
                        max-width: none;
                    }
                }

                > aside {
                    display: none;
                }
            }
        }

        .PageTitle {
            padding-left: 0;
            padding-right: 0;

            text-align: left;
        }

        .hero-container {
            text-align: right;
            max-height: 400px;
            line-height: 0;

            > img {
                max-width: 850px;
                width: 100%;
            }
        }
    }
}
