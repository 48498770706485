@import '../../../../../scss/settings';

.SegmentCarouselSlice.TemplateCarouselSlice.MultipleTemplatesSlice {
    background: none;
    padding-top: 0;

    .gradient-container {
        width: 100%;
        border-radius: 10px;
    }

    .slides {
        height: 627px;
    }

    .SegmentCarouselSlide {
        background: none;

        .cta-link {
            background: $brand-color;
            color: $white;
            margin-top: 20px;
            margin-bottom: 20px;
            box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
        }

        .hero-container {
            max-width: 912px;
            margin-top: 0;
        }
    }

    nav {
        ul {
            justify-content: center;

            a {
                color: $charcoal;
                margin-bottom: 10px;

                &.selected {
                    background: $off-white;
                }
            }
        }
    }

    .PageTitle {
        padding-top: 5px;

        font-weight: $font-weight-semibold;
        font-size: $font-size-2;
        color: $charcoal;
    }

    .PageIntroduction {
        max-width: 790px;
        margin: 0 auto;
        padding-bottom: 0;

        font-size: 22px;
        color: $dark-grey;
    }

    &.has-background-color,
    &.has-background-color + .no-background-color {
        &:after {
            border: 0;
            padding-top: 0;
        }

        &.show-gradient {
            &:after {
                padding-top: 1px;
            }
        }
    }

    &.dark-style {
        nav ul a,
        .PageTitle {
            color: $white;
        }

        .PageIntroduction {
            color: #6E6E71;
        }

        nav ul a.selected {
            background: $darker-grey;
        }
    }

    &.show-gradient {
        nav {
            min-height: 93px;

            ul {
                a {
                    color: $white;
                    margin-bottom: 10px;

                    &.selected {
                        background: rgba(255, 255, 255, 0.25);
                    }
                }
            }
        }

        .gradient-container {
            margin-top: 20px;
            margin-bottom: 60px;
        }
    }

    @media (max-width: 1130px) {
        .slides {
            height: auto;
            padding-bottom: 60%;
        }
    }

    @media (max-width: $break_mobile) {
        &.has-background-color,
        &.has-background-color + .no-background-color {
            padding-bottom: 30px;
        }

        &.show-gradient {
            .gradient-container {
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }

        .PageTitle {
            padding-top: 0;

            font-size: 20px;
        }

        .PageIntroduction {
            font-size: 18px;

            margin-bottom: 30px;
        }

        .slides {
            padding-top: 20px;
            padding-bottom: 0;
        }

        .SegmentCarouselSlide {
            .Section {
                padding-right: 20px;
                padding-left: 20px;
            }

            .hero-container {
                padding-right: 0;
                padding-left: 0;
            }

            .cta-link {
                margin-top: 10px;
                margin-bottom: 25px;
            }
        }

        .swipe-markers {
            padding-top: 0;
            padding-bottom: 20px;
        }
    }
}
