@import '../../../scss/settings';

.CompanyLogo {
    flex: 1;
    flex-basis: 166px;
    width: 166px;

    margin-left: 15px;
    margin-right: 15px;

    color: $dark-grey;

    .logo-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
    }

    // tablet only
    @media (min-width: $break_mobile + 1) and (max-width: $break_tablet) {
        &.hide-tablet {
            display: none;
        }
    }

    @media (max-width: $break_mobile) {
        flex-basis: 100px;
        width: 100px;

        img {
            transform: scale(0.8);
        }

        &.hide-mobile {
            display: none;
        }
    }
}
