@import 'settings';
@import 'reset';
@import 'font-face';

* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 62.5%;
    line-height: 1.333;
}

body {
    height: 100%;
    font-family: $font-body;
    font-size: 1.6em;
    line-height: 1.333;

    color: $charcoal;

    // Medium font properties - test on other devices
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

strong {
    font-weight: bold;
}

a {
    color: $brand-color;
}

.visible-mobile {
    display: none;

    @media (max-width: $break_tablet) {
        display: block;
    }

    @media (max-width: $break_mobile) {
        display: block;
    }
}
