@import '../../../scss/settings';

.MegaGuidePageSliceContainerSection {
    > .content {
        display: grid;

        grid-template-columns: 330px auto;
        grid-column-gap: 60px;

        grid-template-areas: 'aside content';

        .slices-main {
            grid-area: content;
        }

        .slices-aside {
            position: -webkit-sticky;
            position: sticky;
            align-self: flex-start;

            grid-area: aside;

            top: 150px;
        }

        .GuideStepSlice {
            .guide-step-title {
                font-size: 4.4rem;
            }

            .step-subtitle {
                font-size: 3rem;
            }

            &:not(:first-of-type):before {
                display: block;

                width: 10rem;
                height: 8.5rem;

                margin-top: 0rem;
                margin-left: auto;
                margin-right: auto;

                border-top: 2px solid #e6e6e6;
                content: '';
            }

            @media (max-width: $break_laptop) {
                .guide-step-title {
                    font-size: 3.4rem;
                }

                .step-subtitle {
                    font-size: 2.4rem;
                }

                &:not(:first-of-type):before {
                    height: 5.5rem;
                }
            }

            @media (max-width: $break_mobile) {
                margin-top: 6rem;

                .guide-step-title {
                    font-size: 2.4rem;
                }

                .step-subtitle {
                    font-size: 2rem;
                }

                &:not(:first-of-type):before {
                    height: 5.5rem;
                }
            }
        }

        .GuideTextBlockSlice {
            margin-top: 6.5rem;

            .guide-text-title {
                font-family: $font-body;
                font-weight: bold;
                font-size: 3rem;
            }

            @media (max-width: $break_mobile) {
                .guide-text-title {
                    font-size: 2rem;
                }
            }
        }
    }

    @media (min-width: $break_tablet + 1) and (max-width: $break_laptop) {
        > .content {
            grid-template-columns: 250px auto;
            grid-column-gap: 40px;

            .slices-aside {
                top: 110px;
            }
        }
    }

    @media (max-width: $break_tablet) {
        > .content {
            grid-template-columns: 250px auto;
            grid-column-gap: 0;

            .slices-aside {
                top: 110px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        > .content {
            display: block;

            .slices-aside {
                display: none;
            }
        }
    }
}
