@import '../../../scss/settings';

.Header {
    position: relative;

    top: 0;
    left: 0;
    right: 0;
    padding: 30px 15px;
    z-index: $z-index-header;

    background: $charcoal;

    transition: padding ease-in-out 0.3s, background linear 0.15s, box-shadow linear 0.15s;

    .HeaderLogo .MilanoteLogo {
        path {
            fill: $white;
        }

        .milanote-name {
            color: $white;
        }
    }

    .mobile-nav-reveal,
    .mobile-nav-close {
        display: none;
    }

    .nav {
        display: inline-block;
        font-size: $font-size-5;

        li {
            display: inline-block;
            margin-left: 10px;
        }

        .Button {
            padding: 9px 25px 10px 25px;

            &.primary {
                color: $white;
            }

            &.secondary {
                padding: 8px 25px 9px 25px;

                border-color: #a5a5a5;

                color: $mid-light-gray;
                font-weight: normal;
            }
        }
    }

    .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: 100%;
        max-width: 1170px;

        margin: 0 auto;
        padding: 0 15px;
    }

    .header-left {
        display: flex;
        justify-content: center;
    }

    .header-right {
        margin: auto 0;
    }

    &.light {
        @media (min-width: $break_mobile + 1) {
            background: none;

            .HeaderLogo .MilanoteLogo {
                path {
                    fill: $header-title-color;
                }

                .milanote-name {
                    color: $header-title-color;
                }
            }

            .Button {
                &.secondary {
                    color: $dark-grey;
                }
            }
        }
    }

    &.scroll-fixed {
        position: fixed;
    }

    &.shrink-padding {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &.frosted {
        box-shadow: 0 1px 3px 0 rgba(50, 59, 74, 0.15);
        background-color: rgba(255, 255, 255, 0.8);

        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    &.frosted-dark {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
        background-color: rgba(49, 48, 58, 0.9);

        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    @media (max-width: $break_laptop) {
        .Header {
            padding-top: 20px;
            padding-bottom: 20px;

            > .header-content {
                padding-left: 15px;
                padding-right: 15px;
            }

            &.shrink-padding {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        position: relative;
        padding: 15px 0 15px 0;

        z-index: 999999;

        .HeaderLogo .MilanoteLogo {
            svg {
                height: 30px;
                width: 30px;
            }

            .milanote-name {
                font-size: 15.8px;
            }
        }

        &.scroll-fixed {
            position: relative;
        }

        .mobile-nav-reveal,
        .mobile-nav-close {
            display: block;

            width: 40px;
            height: 40px;

            padding: 0;

            font-size: 0;
            color: transparent;

            background: transparent;
            background-image: url('./header-menu-hamburger.svg');
            background-position: center center;
            background-repeat: no-repeat;
        }

        .nav {
            display: block;
            position: fixed;

            background: $white;

            top: 0;
            right: -100%;
            width: 80%;
            height: 100%;

            min-width: 280px;

            box-shadow: 0 11px 15px -7px rgba(50, 59, 74, 0.2), 0 9px 46px 8px rgba(50, 59, 74, 0.12),
                0 24px 38px 3px rgba(50, 59, 74, 0.14);

            transition: right 0.3s ease-in-out;

            &.visible-mobile {
                right: 0;
            }

            > ul {
                position: relative;

                width: 100%;
                height: 100%;

                padding: 20px;

                overflow: scroll;
                -webkit-overflow-scrolling: touch;
            }

            li {
                display: block;
                margin: 10px;

                .Button {
                    display: block;
                    text-align: center;

                    &.secondary {
                        color: $dark-grey;
                    }
                }
            }

            .mobile-nav-close {
                position: absolute;

                top: 10px;
                right: 10px;

                width: 60px;
                height: 60px;
                background-image: url('./header-menu-close.svg');
            }
        }
    }
}
