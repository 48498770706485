@import '../../../scss/settings';

.SuperGuideNavigation {
    background: $light-grey;

    .guide-navigation {
        position: relative;
        top: -280px;

        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 60px;
        padding-left: 50%;

        margin-left: -50%;

        color: $white;
        font-size: 18px;

        background: $brand-purple-color;

        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.25), 0 2px 8px 0 rgba(0, 0, 0, 0.15);

        z-index: 1000;

        .navigation-content {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;

            width: 100%;
            max-width: 940px;
            margin-left: auto;

            .navigation-list {
                margin-right: 25px;

                flex-grow: 1;

                .navigation-list-title {
                    margin-bottom: 15px;
                    font-weight: bold;
                }

                ul {
                    li {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: space-between;

                        padding: 10px 15px 12px 15px;

                        border-top: 1px solid rgba(255, 255, 255, 0.2);

                        &:last-of-type {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                        }

                        .nav-icon {
                            width: 20px;
                            height: 20px;

                            margin-top: 2px;
                            margin-right: 24px;

                            background-position: center center;
                            background-repeat: no-repeat;

                            &.template {
                                background-image: url('./superguide-header-nav-icon-template.svg');
                            }

                            &.video {
                                background-image: url('./superguide-header-nav-icon-video.svg');
                            }
                        }

                        .nav-item-link {
                            color: $white;
                            text-decoration: none;

                            max-width: 58%;
                        }

                        .nav-item-number {
                            margin-right: 10px;
                        }

                        .nav-right {
                            margin-left: auto;
                            text-align: right;
                            color: #bbc1ff;
                        }

                        .nav-right-link {
                            color: $white;
                        }
                    }
                }
            }

            .navigation-hero {
                position: relative;

                max-width: 430px;

                img {
                    display: block;
                    max-width: 100%;
                }

                .navigation-hero-overlay {
                    position: absolute;

                    right: 0;
                    bottom: 0;
                    left: 0;

                    padding: 10px 16px;
                    color: $white;
                    background: rgba(0, 0, 0, 0.6);
                }
            }
        }
    }

    @media (max-width: $break_tablet) {
        height: auto !important;

        .guide-navigation {
            top: 0;
            padding-left: 0;
            margin-left: 0;

            .navigation-content {
                .navigation-list {
                    padding-left: 20px;
                }
            }
        }
    }

    @media (max-width: $break_mobile) {
        .guide-navigation {
            font-size: 16px;
            padding-bottom: 30px;

            .navigation-content {
                .navigation-list {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }

        .navigation-hero {
            display: none;
        }
    }
}
