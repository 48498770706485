@import '../../../scss/settings';

.Guide {
    .header-section-background {
        position: relative;
        background: $light-grey;

        z-index: 0;
    }

    .primary-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding-bottom: 20px;
    }

    .Section.InspirationDetailsSection {
        .guide-summary {
            padding-bottom: 30px;

            p {
                font-size: 24px;
                line-height: 40px;
                color: $charcoal;
            }
        }
    }

    .milanote-summary {
        max-width: 840px;
        padding: 0 44px 68px 44px;
        margin: 0 auto;

        font-size: 18px;
        line-height: 30px;
        color: $dark-grey;
    }

    .guide-title {
        font-family: $font-title;
        font-weight: $font-weight-semibold;
        padding: 0.8em 20px 0.4em 20px;
        color: $header-title-color;
    }

    .guide-header {
        .intro {
            font-size: $font-size-3;
            line-height: 1.6;
            color: #6f6f72;

            .guide-step-title {
                font-family: $font-title;
                font-size: $font-size-2;
            }
        }

        .guide-title {
            padding: 0;
            margin-bottom: 20px;
            font-size: $font-size-2;
        }

        > .content {
            max-width: 840px;
            padding: 68px 44px 22px 44px;
        }
    }

    .guide-content {
        margin: 0 auto 0 auto;
        max-width: 840px;

        font-size: 21px;
        line-height: 1.5;

        ol,
        li {
            padding-left: 1em;

            li {
                padding-left: 0.8em;
            }
        }

        ol {
            list-style: decimal;
        }

        ul {
            list-style: circle;
        }
    }

    .example-board-link {
        margin: 0 auto 70px auto;
        max-width: 840px;

        > .content {
            padding: 5px 44px 52px 44px;
        }
    }

    @media (max-width: $break_desktop) {
        .PageTitle {
            padding-left: 80px;
            padding-right: 80px;
            padding-bottom: 40px;
        }

        .milanote-summary {
            padding-top: 20px;
        }

        .Section.InspirationDetailsSection {
            .guide-summary {
                p {
                    font-size: 20px;
                    line-height: 32px;
                }
            }

            .details {
                padding-right: 80px;
            }
        }
    }

    @media (max-width: $break_tablet) {
        .PageTitle,
        .Section.InspirationDetailsSection {
            max-width: 840px;
            padding-right: 44px;
            padding-left: 44px;
            margin: 0 auto;
        }

        .primary-content {
            padding-bottom: 40px;
        }

        .Section.InspirationDetailsSection {
            .mobile-title {
                display: none;
            }

            .image-section {
                margin-bottom: 0;
            }

            .details {
                padding-top: 30px;
                padding-right: 0;
            }

            .content {
                max-width: 840px;
                margin: 0;
            }
        }

        .milanote-summary {
            padding-top: 30px;
        }

        .guide-title {
            font-size: $font-size-3;
        }

        .guide-header .intro {
            font-size: $font-size-4;

            .guide-step-title {
                font-size: $font-size-3;
            }
        }

        .example-board {
            margin: 0 20px;
        }
    }

    @media (max-width: $break_mobile) {
        font-size: $font-size-4;

        .PageTitle,
        .milanote-summary,
        .guide-header > .content,
        .Section.InspirationDetailsSection {
            padding-left: 20px;
            padding-right: 20px;
        }

        .PageTitle {
            padding-bottom: 20px;
        }

        .primary-content {
            padding-bottom: 30px;
        }

        .milanote-summary {
            padding-bottom: 30px;
        }

        .guide-header {
            > .content {
                padding-top: 20px;
                padding-bottom: 30px;
            }
        }

        .example-board {
            .guide-step-title {
                padding-top: 20px;
                padding-bottom: 25px;
                font-size: $font-size-3;
            }

            .Button {
                margin-bottom: 30px;
            }
        }
    }
}
