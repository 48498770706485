@import '../../../scss/settings';

.MegaGuideTableOfContents {
    width: 100%;
    margin-top: 1.1rem;

    font-size: 1.6rem;
    line-height: 1.5;

    color: #6e6e71;

    .guide-toc-navigation {
        margin-bottom: 3rem;

        ul {
            li {
                a {
                    display: flex;

                    color: #909090;
                    text-decoration: none;

                    &:hover {
                        color: #1b2536;
                    }

                    &.step-major {
                        padding-top: 0.8rem;
                        padding-bottom: 0.8rem;

                        font-size: 1.8rem;
                        font-weight: bold;

                        &.active {
                            color: $charcoal;
                        }
                    }

                    &.step-sub {
                        display: none;

                        padding-top: 0.4rem;
                        padding-bottom: 0.4rem;
                        padding-left: 2rem;

                        font-size: 1.6rem;
                        line-height: 2.4rem;

                        border-left: 2px solid #e2e2e3;

                        &.active {
                            color: #1b2536;

                            border-left: 2px solid #343b49;
                        }

                        &.visible {
                            display: block;
                        }
                    }
                }

                &:first-of-type a.step-major {
                    padding-top: 0.5rem;
                }
            }
        }
    }

    .guide-toc-share-buttons {
        h4 {
            margin-bottom: 1.4rem;
            font-weight: bold;
        }
    }

    @media (max-width: $break_laptop) {
        .guide-toc-header {
            padding-bottom: 2rem;
            margin-bottom: 1rem;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            .UserImage {
                margin-top: 0.5rem;
                margin-right: 1rem;

                width: 4rem;
                height: 4rem;
            }
        }

        .guide-toc-navigation {
            margin-bottom: 2rem;

            h4 {
                margin-bottom: 0.6rem;
            }
        }

        .guide-toc-share-buttons {
            h4 {
                margin-bottom: 1.4rem;
            }
        }
    }

    @media (max-width: $break_mobile) {
        height: auto;
        width: 100%;

        .guide-toc-header {
            display: none;
        }

        .guide-toc-navigation {
            display: none;
        }

        .guide-toc-share-buttons {
            display: none;
        }
    }
}
