@import '../../../../../scss/settings';

.Section.LargeTemplatePreviewSlice {
    .PageTitle {
        font-size: $font-size-2;
    }

    .PageIntroduction {
        font-size: 22px;
        max-width: 790px;
        margin: 0 auto;
    }

    .preview-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        min-height: 200px;
        padding: 8% 10% 0 10%;

        border-radius: 10px;

        .image {
            width: 100%;
            box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
        }

        .Button {
            margin: 30px 0 40px 0;
        }
    }

    @media (max-width: $break_mobile) {
        .PageTitle {
            padding-top: 0;

            font-size: 20px;
        }

        .PageIntroduction {
            font-size: 18px;

            margin-bottom: 30px;
        }

        .preview-section {
            padding: 20px;

            .Button {
                margin: 20px 0 0 0;
            }
        }
    }
}
