@import '../../../scss/settings';

.AsideSection {
    > .content {
        display: flex;
        overflow: hidden;

        > aside {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 600px;

            padding-left: 30px;
            padding-right: 30px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        > section {
            display: flex;
            width: 360px;
            flex-basis: 360px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .section-content {
                max-width: 690px;
            }
        }
    }

    // TODO This needs more design
    &.no-margin {
        &.right {
            > .content {
                margin-right: 0;

                > aside {
                    padding-right: 0;
                }
            }
        }

        &.left {
            > .content {
                margin-left: 0;

                > aside {
                    padding-left: 0;
                }
            }
        }
    }

    &.left > .content {
        flex-direction: row;

        > aside {
            margin-right: auto;
        }
    }

    &.right > .content {
        flex-direction: row-reverse;

        > aside {
            margin-left: auto;
        }
    }

    &.bottom > .content {
        flex-direction: column-reverse;
        align-items: center;

        > aside {
            height: auto;
            max-width: 968px;
        }

        > section {
            width: auto;

            .section-content {
                width: auto;
                text-align: center;
            }
        }
    }

    @media (max-width: $break_desktop) {
        &.right .content {
            section {
                margin-left: 30px;
            }
        }

        &.left .content {
            section {
                margin-right: 30px;
            }
        }

        section {
            .section-content {
                font-size: $font-size-4;

                > h2 {
                    font-size: $font-size-3;
                }
            }
        }
    }

    @media (max-width: $break_tablet) {
        &.right .content,
        &.left .content,
        &.bottom .content {
            flex-direction: column;

            aside {
                height: auto;
                margin: 0;
                padding: 20px 30px 20px 30px;

                img {
                    margin: 0;
                }
            }

            section {
                flex-basis: auto;
                text-align: center;
                width: auto;

                margin: 0;
                padding: 20px 60px 0 60px;

                .section-content {
                    max-width: 500px;
                }
            }
        }

        &.bottom .content {
            flex-direction: column-reverse;
        }
    }

    @media (max-width: $break_mobile) {
        &.right .content,
        &.left .content,
        &.bottom .content {
            aside {
                padding: 0 20px 0 20px;
            }

            section {
                padding: 20px;

                .section-content {
                    max-width: auto;
                }
            }
        }
    }
}
