@import '../../../../../scss/settings';

.GuideStepSlice {
    margin-top: 9rem;

    position: relative;

    font-size: 2rem;
    line-height: 1.5;

    color: #6f6f72;

    .title-container {
        margin-bottom: 2rem;

        .guide-step-title {
            font-family: $font-title;
            font-size: $font-size-2;
            line-height: 1.33;
            color: $charcoal;

            font-weight: 600;
        }
    }

    .step-explanation {
        color: $charcoal;

        p {
            margin-bottom: 1.9rem;
        }

        a {
            color: $charcoal;
        }

        ul {
            li {
                padding-left: 0.5rem;
                margin-left: 2rem;

                margin-bottom: 0.5rem;

                list-style: disc;
            }
        }
    }

    .step-image {
        margin-top: 2.8rem;

        img {
            width: 100%;
            height: auto;
            border-radius: 8px;
        }
    }

    &:first-of-type {
        margin-top: 0;
    }

    @media (max-width: $break_laptop) {
        margin-top: 6rem;

        .title-container {
            margin-bottom: 1.8rem;
        }

        .step-number {
            margin-top: -4px;
            margin-right: 2rem;
            font-size: 2.4rem;
        }

        .guide-step-title {
            display: inline;
            margin-bottom: 0;

            font-size: $font-size-3;
            line-height: 1.25;
        }

        .step-explanation {
            font-size: 1.8rem;
            line-height: 1.6;
        }
    }

    @media (max-width: $break_mobile) {
        padding-left: 15px;
        padding-right: 15px;

        margin-top: 4.5rem;

        font-size: 18px;

        .title-container {
            margin-bottom: 1rem;
        }

        .step-image {
            img {
                width: 100%;
                border-radius: 0;
            }
        }
    }
}
