@import '../../../scss/settings';

.InspirationLanding {
    background: $charcoal;

    .title-section {
        padding-bottom: 50px;
    }

    .segments {
        padding-bottom: 100px;
    }

    @media (max-width: $break_tablet) {
        .plans {
            > li {
                .Plan {
                    width: auto;
                }
            }
        }
    }

    @media (max-width: $break_mobile) {
        .title-section {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
