@import '../../../../../scss/settings';

.SegmentCarouselSlice {
    padding-top: 40px;

    background: $charcoal;
    color: $white;

    nav {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        padding-top: 30px;
        padding-bottom: 20px;

        font-size: 18px;

        .usage {
            display: block;
            padding: 5px 17px 7px 17px;

            font-weight: $font-weight-semibold;
            color: #e5e5e5;

            white-space: nowrap;
        }

        ul {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            flex-wrap: wrap;

            a {
                display: block;
                padding: 5px 17px 7px 17px;

                border-radius: 3px;

                color: $white;
                font-weight: $font-weight-semibold;
                text-decoration: none;

                transition: background-color linear 0.3s;

                white-space: nowrap;

                &.selected {
                    color: #e5e5e5;
                    background-color: rgba(255, 255, 255, 0.15);
                }
            }
        }
    }

    .swipe-markers {
        display: none;
    }

    @media (min-width: $break_mobile + 1) {
        .slides {
            position: relative;
            height: 843px;
            overflow: hidden;

            .slide {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;

                opacity: 0;

                transition: opacity linear 0.3s;

                pointer-events: none;

                &.visible {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    @media (max-width: 1130px) {
        .slides {
            height: auto;
            padding-bottom: 70%;
        }
    }

    @media (max-width: $break_tablet) {
        nav {
            font-size: 16px;

            .usage {
                padding: 4px 10px 5px 10px;
            }

            ul {
                a {
                    padding: 4px 10px 5px 10px;
                }
            }
        }
    }

    @media (max-width: $break_mobile) {
        margin-top: 0;
        padding-top: 20px;

        nav {
            display: none;
        }

        .slides {
            padding-bottom: 0;
        }

        .swipe {
            overflow: hidden;
            visibility: hidden;
            position: relative;

            .swipe-wrap {
                overflow: hidden;
                position: relative;

                > div {
                    float: left;
                    width: 100%;
                    position: relative;
                }
            }
        }

        .swipe-markers {
            display: flex;

            align-items: center;
            justify-content: center;

            padding: 20px 0 40px 0;

            > div {
                width: 7px;
                height: 7px;

                margin: 0 3px;
                border-radius: 50%;

                background: $mid-grey;

                &.active {
                    background: $white;
                }
            }
        }
    }
}
