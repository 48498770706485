@import '../../../../../scss/settings';

.SegmentCarouselSlice.TemplateCarouselSlice {
    .TemplateCarouselSlide {
        .PageTitle {
            max-width: none;
            padding-bottom: 32px;
        }

        .hero-container {
            max-width: 946px;
            margin-top: 49px;

            .image-link {
                text-decoration: none;
            }

            img {
                border-radius: 3px;

                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
            }
        }
    }

    @media (max-width: $break_tablet) {
        .TemplateCarouselSlide {
            .hero-container {
                max-width: 750px;
            }
        }
    }
}
