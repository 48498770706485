@import '../../../scss/settings';

.MobileAppLandingPage {
    .Header {
        background: $charcoal;
    }
}

.mobile-app-landing-page-header-container {
    padding-top: 40px;
    background: $charcoal;

    @media (max-width: $break_mobile) {
        padding-top: 0;
    }
}

.FeaturePageHeader.MobileAppLandingPageHeader {
    background: none;

    .spacer {
        padding-bottom: 85%;
    }

    .PageTitle {
        font-size: 42px;
        line-height: 50px;
    }

    .PageIntroduction {
        font-size: 20px;
        line-height: 30px;
    }

    .extra-details {
        max-width: 380px;
        margin-top: 60px;
        color: $mid-grey;
    }

    .Button {
        display: flex;
        align-items: center;

        width: 292px;
        padding: 7px 0 10px 20px;

        .apple-logo {
            display: block;
            width: 19px;
            height: 23px;
            margin-right: 10px;

            background-image: url('../../components/button/icon/apple-logo.svg');
        }

        .google-play-logo {
            display: block;
            width: 18px;
            height: 20px;
            margin-right: 10px;

            background-image: url('../../components/button/icon/google-play-logo.svg');
        }

        &.android-button {
            margin-top: 16px;
        }
    }

    @media (min-width: $break_mobile + 1) {
        .AsideSection > .content .section-content {
            padding-bottom: 100px;
        }
    }

    @media (max-width: $break_desktop) {
        .AsideSection > .content .section-content {
            max-width: 460px;
        }

        .PageTitle {
            font-size: 30px;
            line-height: 40px;
        }

        .PageIntroduction {
            font-size: 18px;
            line-height: 24px;
        }

        .extra-details {
            margin-top: 40px;
            font-size: 16px;
        }
    }

    // Tablet only
    @media (min-width: $break_mobile + 1) and (max-width: $break_tablet) {
        .AsideSection > .content {
            section {
                max-width: 380px;

                .section-content {
                    padding-bottom: 70px;
                }
            }
        }

        .hero-container {
            .image {
                top: auto;
                bottom: -40px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        .hero-container {
            padding-top: 30px;
            max-height: 480px;
        }

        .extra-details {
            margin-top: 30px;
            margin-bottom: 20px;
            color: $mid-grey;
        }
    }
}
