@import '../../../scss/settings';

.ReleaseNotesPage {
    background: $charcoal;

    .title-section {
        padding-bottom: 20px;
    }

    .AsideSection > .content {
        aside {
            justify-content: flex-start;
            align-items: flex-start;

            width: 15%;
            height: auto;

            padding-top: 20px;
            padding-right: 20px;
            padding-left: 15px;
        }

        section {
            display: block;
            flex-grow: 1;
            width: 85%;
            flex-basis: auto;

            .section-content {
                max-width: none;
            }

            .FloatingPanel {
                width: auto;

                .panel {
                    max-width: none;
                    width: 100%;
                    margin: 0;
                }
            }
        }

        .pagination {
            display: flex;
            justify-content: center;

            .page-nav {
                padding: 0 10px;
            }
        }

        .no-results {
            padding: 50px 0 100px 0;
            color: $mid-grey;
            font-size: 24px;
            text-align: center;
        }
    }

    @media (max-width: $break_tablet) {
        .AsideSection > .content section {
            margin-right: 20px;
        }
    }

    @media (max-width: $break_mobile) {
        .AsideSection > .content {
            display: block;

            section {
                width: auto;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}
