@import '../../../../../scss/settings';

.SegmentCarouselSlide {
    background: $charcoal;
    overflow: hidden;
    max-width: 100%;

    .PageTitle {
        max-width: 688px;

        padding-bottom: 24px;

        color: $white;
        font-size: 36px;

        text-align: center;
    }

    .cta-link {
        padding: 8px 40px 10px 40px;
        text-decoration: none;

        border-radius: 3px;

        color: $charcoal;
        font-weight: 600;

        background: #f8f8f8;
    }

    .hero-container {
        width: 1140px;
        margin-top: 33px;
        margin-bottom: 10px;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    > .Section > .content > .content-container,
    > .Section > .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    &.wide-title {
        .PageTitle {
            max-width: 820px;
        }
    }

    @media (max-width: 1130px) {
        .hero-container {
            width: auto;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @media (max-width: $break_tablet) {
        .PageTitle {
            max-width: 550px;
            padding-bottom: 40px;

            font-size: 30px;
        }

        &.wide-title {
            .PageTitle {
                max-width: 750px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        .PageTitle {
            max-width: none;
            padding-right: 20px;
            padding-bottom: 35px;
            padding-left: 20px;

            font-size: 22px;
        }

        &.wide-title {
            .PageTitle {
                max-width: none;
            }
        }

        .cta-link {
            padding: 8px 20px 10px 20px;
            text-align: center;
        }
    }
}
