@import '../../../../../scss/settings';

// FIXME A lot of this is shared with the FeatureSectionSlice
.WideFeatureSectionSlice.AsideSection {
    position: relative;
    overflow: hidden;
    padding: 0 30px;

    h2 {
        font-size: $font-size-3;
        font-family: $font-title;
        font-weight: $font-weight-semibold;

        margin-bottom: 22px;
        color: $charcoal;
    }

    .body {
        text-align: left;
    }

    .summary {
        font-size: $font-size-4;
        line-height: 27px;
        margin-bottom: 17px;
        color: $dark-grey;
    }

    .details {
        font-size: $font-size-5;
        line-height: $font-size-3;
        color: $dark-grey;

        ul {
            padding-left: 17px;
            list-style: disc;

            li {
                margin-bottom: 15px;
            }
        }
    }

    .content {
        section {
            text-align: left;
        }
    }

    // Desktop only
    @media (min-width: $break_desktop) {
        &.no-margin.left {
            > .content {
                .section-content {
                    margin-left: 100px;
                    margin-right: 14px;
                }
            }
        }

        &.no-margin.right {
            > .content {
                .section-content {
                    margin-left: 14px;
                    margin-right: 100px;
                }
            }
        }
    }

    // Tablet only
    @media (min-width: $break_tablet_portrait + 1) and (max-width: $break_tablet) {
        &.left {
            > .content {
                flex-direction: row;
            }
        }

        &.right {
            > .content {
                flex-direction: row-reverse;
            }
        }

        .content {
            section {
                padding: 0;

                .section-content {
                    padding: 0;

                    h2 {
                        font-size: 22px;
                    }

                    .summary {
                        font-size: 18px;
                    }

                    .body {
                        margin: 0;
                    }
                }
            }
        }
    }

    // Desktop and tablet
    @media (min-width: $break_tablet_portrait + 1) {
        > .content {
            position: relative;

            // This is used to show the "discs" in the unordered lists
            overflow: visible;

            > section {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;
                width: auto;

                margin-top: 70px;
                margin-bottom: 70px;

                .section-content {
                    max-width: none;
                }

                .body {
                    position: relative;
                    z-index: 1;
                }
            }

            > aside {
                flex-grow: 0;
                flex-shrink: 0;
                padding: 0;
                margin-left: 14px;
                height: auto;
            }
        }

        &.no-margin.left,
        &.no-margin.right {
            > .content {
                margin-right: auto;
                margin-left: auto;
            }
        }

        &.no-margin.left {
            .section-content {
                margin-left: 60px;
                margin-right: 14px;
            }
        }

        &.no-margin.right {
            .section-content {
                margin-left: 14px;
                margin-right: 60px;
            }
        }
    }

    @media (max-width: $break_tablet_portrait) {
        padding: 50px 0 0 0;

        .content {
            section {
                padding: 0;
                margin: 0 30px;

                .section-content {
                    padding: 30px 0 50px 0;
                    max-width: none;

                    .body {
                        margin: 0 30px;
                        text-align: left;
                    }
                }
            }
        }
    }
}
