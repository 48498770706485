@import '../../../scss/settings';

.Guide2020PageSliceContainerSection {
    > .content {
        display: grid;

        grid-template-columns: 64% auto 330px;

        grid-template-areas: 'content . aside';

        .slices-main {
            grid-area: content;
        }

        .slices-aside {
            position: -webkit-sticky;
            position: sticky;
            align-self: flex-start;

            grid-area: aside;

            top: 150px;
        }
    }

    @media (min-width: $break_tablet + 1) and (max-width: $break_laptop) {
        > .content {
            grid-template-columns: 64% auto 250px;
        }
    }

    @media (max-width: $break_tablet) {
        > .content {
            grid-template-columns: auto 60px 250px;
        }
    }

    @media (max-width: $break_mobile) {
        > .content {
            display: block;

            .slices-aside {
                display: none;
            }
        }
    }
}
