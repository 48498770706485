@import '../../../scss/settings';

.Template {
    display: flex;
    flex-direction: column;
    position: relative;

    .detail-section {
        background: $charcoal;
        color: $white;

        padding-top: 16.2rem;

        .Breadcrumb {
            .BreadcrumbEntry {
                a {
                    color: #808080;
                }

                &.inactive a {
                    color: $white;
                }
            }
        }

        .PageTitle {
            font-size: 4.4rem;
            line-height: 1.27;
        }

        .use-template-cta {
            padding-top: 10px;
            padding-bottom: 23px;
            text-align: center;
        }

        .preview-image {
            position: relative;

            margin-top: 32px;
            margin-bottom: 48px;
            margin-left: -15px;
            margin-right: -15px;

            .image-container {
                position: relative;

                padding-bottom: calc(100% * (9 / 16));

                img {
                    position: absolute;

                    width: 100%;
                    height: 100%;

                    border-radius: 4px;
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
                }
            }

            .TemplateCTAPanel {
                position: absolute;
                right: 65px;
                bottom: -30px;
            }
        }

        .template-info {
            display: grid;

            grid-template-columns: 64% auto 330px;
            grid-template-rows: auto auto;

            grid-template-areas:
                'title . .'
                'description . features';

            margin-right: 0;
            margin-left: 0;
            margin-bottom: 40px;

            font-size: 20px;
            line-height: 1.4;

            color: #bebdc8;

            h3 {
                grid-area: title;

                margin-bottom: 2.5rem;

                font-size: 3.2rem;
                font-weight: 600;
                font-family: $font-title;
                color: $white;
            }

            .description {
                grid-area: description;

                color: #bebdc8;

                p {
                    margin-bottom: 0.55em;
                }

                a {
                    color: #bebdc8;
                }

                ul {
                    padding-left: 30px;
                    list-style: disc;

                    li {
                        padding-left: 5px;
                        margin-bottom: 8px;
                    }
                }
            }

            .features {
                grid-area: features;

                ul {
                    list-style: url('./template-tick.svg');

                    li {
                        margin-bottom: 0.55em;
                    }
                }
            }
        }
    }

    .template-angle-divider {
        height: 166px;
        background: url('./template-header-background.svg');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 2806px 166px;
        z-index: 1;
    }

    @media (max-width: $break_laptop) {
        .detail-section {
            padding-top: 11rem;

            .preview-image {
                margin-left: 50px;
                margin-right: 50px;
            }

            .template-info {
                width: 1010px;
                margin-left: auto;
                margin-right: auto;
                grid-template-columns: 64% auto 250px;

                .features {
                    font-size: 18px;

                    ul {
                        li {
                            margin-bottom: 0.5rem;
                        }
                    }
                }
            }
        }
    }

    // Laptop only
    @media (min-width: $break_tablet + 1) and (max-width: $break_laptop) {
        .Guide2020PageSliceContainerSection > .content {
            margin-left: auto;
            margin-right: auto;
            width: 1010px;
        }
    }

    // Tablet only
    @media (min-width: $break_mobile + 1) and (max-width: $break_tablet) {
        .Guide2020PageSliceContainerSection > .content {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    // tablet
    @media (max-width: $break_tablet) {
        .detail-section {
            .preview-image {
                margin-left: 80px;
                margin-right: 80px;

                .TemplateCTAPanel {
                    right: 30px;
                }
            }

            .template-info {
                width: auto;
                margin-left: 30px;
                margin-right: 30px;
                grid-template-columns: auto 60px 250px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        .detail-section {
            padding-top: 30px;

            .PageTitle {
                font-size: 34px;
            }

            .preview-image {
                margin: 20px;
            }

            .template-info {
                display: block;
                margin-left: 30px;
                margin-right: 30px;
                margin-bottom: 60px;

                h3 {
                    font-size: 21px;
                    margin-bottom: 0.55em;
                }

                .features {
                    display: none;
                }
            }
        }

        .TemplateCTAPanel {
            display: none;
        }
    }
}
