@import '../../../scss/settings';

.InspirationCategorySlice {
    .content {
        border-bottom: 1px solid $plan-divider-color;
    }

    &:last-of-type {
        .content {
            border-bottom: 0;
            margin-bottom: 100px;
        }
    }

    // Tablet and smaller
    @media (max-width: $break_tablet) {
        &:last-of-type {
            .content {
                margin-bottom: 50px;
            }
        }
    }

    // Mobile only
    @media (max-width: $break_mobile) {
        &:last-of-type {
            .content {
                margin-bottom: 0;
            }
        }
    }
}
