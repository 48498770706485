@import '../../../scss/settings';

.BlogPost {
    .BlogTitle {
        padding: 28px 25px 23px 25px;
        background-color: $blog-brand-color;
        position: absolute;
        width: 100%;
        left: 0;

        .title-image-container {
            max-width: 600px;
            margin: 0 auto;
            border-bottom: 1px solid rgba(207, 205, 210, 0.3);
            padding: 0 66px;

            .title-image-link {
                background-image: url('../../components/blog/blog-post-title.png');
                width: 100%;
                padding: 5.7%;
                background-size: 100% auto;
                background-repeat: no-repeat;
                margin: 5px auto;
            }
        }

        .title-description {
            padding: 14px 0;
            color: $white;

            a {
                color: $white;
            }
        }

        @media (max-width: $break_mobile) {
            margin-top: 0;
            padding: 14px 20px 5px;
            position: relative;

            .title-image-container {
                padding: 0 15px;

                .title-image {
                    max-width: none;
                }
            }

            .title-description {
                font-size: 14px;
                line-height: 1.43;
                padding: 15px 0;
            }
        }
    }

    .content-container {
        padding: 220px 50px 35px;
        max-width: 850px;
        margin: 0 auto;

        .post-title {
            font-family: $font-blog-title;
            font-size: 56px;
            font-weight: 600;
            line-height: 1.07;
            color: $charcoal;

            @media (max-width: $break_mobile) {
                font-size: 32px;
                line-height: 1.25;
            }
        }

        .author-container {
            padding: 21px 0;
            display: flex;

            .post-author {
                font-family: $font-blog-body;
                font-size: 16px;
                line-height: 1.25;
                color: $plan-enterprise-color;
                margin-right: 15px;
            }

            .tag-link {
                text-decoration: underline;
                margin: 0 15px 0 0;
                font-family: $font-blog-body;
                font-size: 16px;
                line-height: 1.25;
            }
        }

        .post-content-section {
            margin: 21px 0;

            .post-content {
                color: $modal-secondary-color;
                font-size: 20px;
                line-height: 1.5;

                p {
                    font-family: $font-blog-body;
                    margin-bottom: 20px;
                    word-wrap: break-word;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                hr {
                    width: 200px;
                    margin: 52px auto 48px auto;
                    border: none;
                    border-top: 1px solid $plan-divider-color;
                }

                h1,
                h2,
                h3,
                h4 {
                    font-size: 28px;
                    font-family: $font-blog-title;
                    line-height: 1.07;
                    font-weight: 600;
                    color: #31303a;
                    margin-top: 50px;
                    margin-bottom: 11px;

                    @media (max-width: $break_mobile) {
                        font-size: 24px;
                    }
                }

                h3 {
                    font-size: 24px;

                    @media (max-width: $break_mobile) {
                        font-size: 20px;
                    }
                }

                blockquote {
                    border-left: 2px solid #6f6f72;
                    margin-left: -20px;
                    margin-bottom: 50px;
                    margin-top: 50px;
                    padding-left: 18px;
                    font-style: italic;
                }

                .image-caption-container {
                    margin-bottom: 52px;
                    margin-top: 52px;
                }

                .image-guide-link-container {
                    display: inline-block;
                    position: relative;

                    margin-bottom: 52px;

                    .image-guide-link {
                        display: block;
                        position: absolute;

                        bottom: -10px;
                        right: -10px;

                        z-index: 2;
                        padding: 13px 45px 16px 25px;
                        font-size: 16px;
                        border: 1px solid #b5b5b5;
                        border-radius: 3px;
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                        color: #6f6f72;
                        background: #ffffff;
                        background-position: 96% center;

                        .title {
                            font-family: $font-title;
                            color: #31303a;
                        }

                        .cta span {
                            color: #909090;
                        }

                        .arrow {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            width: 40px;

                            font-size: 0;
                            color: transparent;

                            background: url('./guide-link-arrow.svg') no-repeat center center;
                        }
                    }
                }

                div[data-oembed-type='embed'],
                .block-img {
                    margin-bottom: 52px;
                    margin-top: 52px;
                }

                // Assumes 9:16 aspect ratio
                div[data-oembed-type='embed'] {
                    position: relative;
                    width: 946px;
                    height: 532px;
                    margin-left: -100px;
                }

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: 100%;
                    height: 100%;
                }

                img {
                    height: auto;
                    width: 946px;
                    margin-left: -100px;
                    display: block;
                }

                li {
                    padding-left: 10px;
                    margin-bottom: 18px;
                }

                ol,
                ul {
                    font-family: $font-blog-body;
                    padding-left: 24px;
                    list-style: decimal;
                    margin-bottom: 36px;
                }

                ul {
                    list-style: disc;
                    li {
                        margin-bottom: 22px;
                    }
                }

                em {
                    font-style: italic;
                }

                figcaption {
                    font-family: $font-blog-body;
                    text-align: center;
                    font-size: 16px;
                    line-height: 1.25;
                    margin: 20px 10% 0 10%;
                    color: $plan-enterprise-color;
                }

                @media (max-width: $break_tablet) {
                    img {
                        width: 100%;
                        margin-left: 0;
                    }

                    // Assumes 9:16 aspect ratio
                    div[data-oembed-type='embed'] {
                        width: 100%;
                        height: auto;
                        padding-bottom: 56.25%;
                        margin-left: 0;
                    }
                }

                @media (max-width: $break_mobile) {
                    font-size: 16px;
                    line-height: 1.56;

                    div[data-oembed-type='embed'],
                    blockquote,
                    .block-img,
                    .image-caption-container {
                        margin-bottom: 22px;
                        margin-top: 22px;
                    }

                    h1,
                    h2,
                    h3,
                    h4 {
                        margin-top: 36px;
                    }

                    hr {
                        margin-top: 22px;
                        margin-bottom: 20px;
                    }

                    ol,
                    ul,
                    p {
                        margin-bottom: 22px;
                    }

                    figcaption {
                        font-size: 14px;
                        margin-top: 12px;
                    }

                    blockquote {
                        margin-left: 0;
                    }

                    .image-guide-link-container {
                        margin-bottom: 0;
                        margin-top: 0;

                        .image-guide-link {
                            display: none;
                        }
                    }
                }
            }
        }

        @media (max-width: $break_mobile) {
            padding: 19px 20px 10px;

            .post-author {
                padding-bottom: 5px;
            }

            .author-container {
                display: block;
                padding: 5px 0;
            }
        }
    }

    .post-footer-container {
        position: absolute;
        left: 0;
        width: 100%;

        @media (min-width: $break_tablet) {
            height: 140px;
        }
    }
}
