@import '../../../scss/settings';

.Footer {
    max-width: 1440px;
    margin: 0 auto;

    background: $charcoal;
    color: $white;
    line-height: 40px;

    .content {
        margin: 0 auto;
        max-width: 1000px;
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .logo-link {
        color: $white;
        text-decoration: none;
    }

    .links {
        max-width: 1440px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        .group {
            width: 225px;
            margin-left: 20px;
            margin-bottom: 25px;
        }

        .group-header {
            font-weight: $font-weight-semibold;
        }

        a {
            text-decoration: none;
            color: #b5b5b5;
        }

        a:hover {
            cursor: pointer;
        }
    }

    .home-link {
        text-decoration: none;
    }

    .MilanoteLogo {
        margin-top: 100px;

        span {
            color: $white;
        }

        path {
            fill: $white;
        }
    }

    &.full-width {
        max-width: none;
    }

    // Somewhere between tablet landscape & tablet portrait
    @media (max-width: 1000px) {
        line-height: 30px;

        .group:nth-of-type(n + 3) {
            padding-top: 20px;

            border-top: 1px solid $dark-grey;
        }

        .content {
            max-width: 500px;
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .MilanoteLogo {
            margin-top: 30px;
        }
    }

    @media (max-width: $break_mobile) {
        .content {
            max-width: 225px;
            text-align: center;

            .links {
                flex-direction: column;
            }

            .group {
                margin-left: 0;

                &:nth-of-type(n + 2) {
                    padding-top: 20px;
                    border-top: 1px solid $dark-grey;
                }
            }
        }
    }
}
