@import '../../../scss/settings';

.BlogPostLink {
    display: flex;
    flex-direction: column;
    text-decoration: none;

    .link-container {
        text-decoration: none;
        display: block;
        padding-bottom: 17px;
    }

    .image-container {
        display: block;
        width: 100%;

        position: relative;

        border: 1px solid rgba(0, 0, 0, 0.1);

        overflow: hidden;
        border-radius: 3px;

        margin-bottom: 18px;

        transition: opacity 0.2s ease-in-out;

        .image {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .Author {
        margin-top: auto;
        margin-bottom: 0;
        padding-top: 25px;

        .UserImage {
            width: 50px;
            height: 50px;
        }
    }

    .post-title {
        font-family: $font-blog-title;
        font-size: 24px;
        line-height: 1.25;
        font-weight: 600;
        color: $charcoal;
    }

    .tag-link {
        margin-top: 0;
    }

    @media (max-width: $break_tablet) {
        &:nth-child(3) {
            display: none;
        }
    }

    @media (max-width: $break_mobile) {
        &:nth-child(3) {
            display: initial;
        }

        .image-container {
            margin-bottom: 13px;
        }

        .link-container {
            padding-bottom: 4px;
        }
    }
}
