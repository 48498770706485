@import '../../../scss/settings';

.AppDownload {
    background-color: $light-grey;

    .PageTitle {
        padding: 0;
        text-align: left;
        color: $charcoal;
        font-weight: $font-weight-semibold;
    }

    .PageIntroduction {
        padding: 0;
        margin-top: 14px;
        margin-bottom: 37px;
        color: $dark-grey;
        text-align: left;
    }

    .additional-info {
        margin-top: 40px;

        font-size: $font-size-5;
        color: $dark-grey;
        line-height: 24px;

        a {
            color: inherit;
        }
    }

    .AsideSection.left {
        padding-top: 47px;

        .content {
            overflow: visible;

            section {
                width: 39%;
                flex-basis: auto;

                .section-content {
                    margin-top: -70px;
                    max-width: 460px;
                    flex-basis: auto;
                }
            }

            aside {
                width: 60%;
                padding: 0;
                height: auto;

                margin-right: 12px;
                padding-right: 38px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .button-container {
                .Button {
                    margin-right: 10px;
                    margin-bottom: 10px;
                    padding-right: 30px;
                    padding-left: 30px;
                }
            }
        }
    }

    @media (max-width: $break_desktop) {
        .AsideSection.left {
            .content {
                aside {
                    padding-left: 20px;
                    min-width: 0;
                }
            }
        }
    }

    @media (max-width: $break_tablet) {
        .AsideSection.left .content {
            flex-direction: column-reverse;
        }

        .PageTitle {
            padding: 0;
            text-align: center;
        }

        .PageIntroduction {
            text-align: center;
        }

        .AsideSection.left {
            padding-top: 10px;

            .content {
                section {
                    width: auto;

                    .section-content {
                        margin-top: 0;
                    }
                }

                aside {
                    width: auto;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    @media (max-width: $break_mobile) {
        .AsideSection.left {
            padding-top: 75px;
            margin-top: -75px;
            .content {
                section {
                    .section-content {
                        width: auto;
                    }
                }
            }
        }
    }
}
