@import '../../../scss/settings';

.GuideSummaryPanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 20px;
    height: 440px;

    color: $charcoal;

    background: $white;

    overflow: hidden;

    text-decoration: none;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

    .article-summary {
        display: flex;
        flex-direction: column;

        flex-shrink: 1;
        flex-grow: 1;

        overflow: hidden;
    }

    .author-section {
        flex-shrink: 0;
        flex-grow: 0;

        margin-top: 20px;
    }

    .thumbnail {
        width: 320px;
        height: 180px;

        margin-bottom: 20px;

        object-fit: cover;
    }

    .title {
        font-family: $font-title;
        font-size: 18px;
        line-height: 1.25;
        color: $charcoal;
        margin: 0 50px 10px 0;
    }
}
