@import '../../../scss/settings';

.FloatingPanel {
    display: flex;
    justify-content: center;

    .panel {
        flex-grow: 1;
        max-width: 1000px;

        margin: 0 auto;
        padding: 35px 30px;
        background: $white;

        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px rgba(48, 48, 58, 0.2);
        border-radius: 6px;
    }

    @media (max-width: $break_mobile) {
        display: block;

        .panel {
            display: block;
            padding: 25px 20px;
        }
    }
}
