@import '../../../scss/settings';

.Error404 {
    .error-404-section {
        background-size: 100% auto;
        background-position: top center;
        background-repeat: no-repeat;
        background-color: $charcoal;

        .content {
            padding: 160px 100px;
            color: $white;
            font-size: 18px;

            .quote {
                font-family: $font-title;
                font-size: 70px;
                line-height: 1.14;
                max-width: 640px;
            }

            .quote-attribution {
                font-size: $font-size-3;
            }

            .title {
                margin-top: 45px;

                font-weight: 600;
                font-size: $font-size-3;
            }

            .suggestions {
                margin-top: 45px;
            }
        }

        @media (max-width: $break_tablet) {
            .content {
                padding: 160px 20px;

                .quote {
                    font-family: $font-title;
                    font-size: $font-size-1;
                }

                .quote-attribution {
                    font-size: $font-size-4;
                }

                .title {
                    margin-top: 30px;
                    font-size: $font-size-4;
                }

                .suggestions {
                    margin-top: 30px;
                }
            }
        }

        @media (max-width: $break_mobile) {
            background-size: auto 100%;

            .content {
                padding: 20px 20px 160px 20px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        .Header {
            display: none;
        }
    }
}
