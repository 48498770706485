@import '../../../scss/settings';

.Plans {
    background-size: auto 100%;

    .title-section {
        padding-top: 75px;
        text-align: center;
    }

    .plans-section {
        padding-top: 2rem;
        padding-bottom: 120px;

        font-family: $font-body-alt;

        .panel {
            max-width: 100rem;
        }
    }

    .plans {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 3.8rem;

        padding-top: 2rem;
        padding-bottom: 3rem;
    }

    @media (max-width: 1375px) {
        background-size: auto 100%;
    }

    @media (max-width: $break_tablet) {
        .plans-section {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    @media (max-width: 769px) {
        .plans {
            grid-column-gap: 2rem;
        }
    }

    @media (max-width: $break_mobile) {
        background: $charcoal;
        background-image: none !important;

        .title-section {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .PageTitle {
            color: $white;
        }

        .plans-section {
            padding: 10px;
        }

        .MilanoteLogo {
            .milanote-name {
                color: $white;
            }
        }

        .plans {
            display: block;

            .Plan {
                width: 100%;
                max-width: 34rem;

                margin-left: auto;
                margin-right: auto;
                margin-bottom: 5rem;
                padding-top: 5rem;

                border-top: 1px solid $plan-divider-color;

                .disclaimer {
                    min-height: 0;
                    margin-bottom: 2rem;
                }

                .footer.empty {
                    display: none;
                }

                .detail-switch.empty {
                    display: none;
                }

                &:first-of-type {
                    padding-top: 0;
                    border-top: none;
                }

                &:last-of-type {
                    margin-bottom: 3rem;
                }
            }
        }

        .enterprise-option {
            margin-top: 0;
        }
    }
}
