@import '../../../scss/settings';

.UserName {
    font-size: 14px;

    .name {
        font-weight: 600;
    }

    .company {
        margin-top: 10px;

        img {
            max-height: 36px;
        }
    }
}
