@import '../../../scss/settings';

.TemplateListingEntry {
    background: white;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

    .template-thumbnail-container {
        position: relative;

        background: $charcoal;

        .template-thumbnail {
            display: block;
            width: 100%;
            height: auto;
            opacity: 0.8;

            transition: opacity 0.3s ease-out;
        }
    }

    .template-title {
        font-weight: $font-weight-semibold;
        font-size: 22px;
        line-height: 22px;
        color: #31303a;
        margin: 28px 20px 30px 20px;

        text-align: center;
    }

    &:hover {
        .template-thumbnail {
            opacity: 1;
        }
    }
}
