@import '../../../../../scss/settings';

.CompaniesSectionSlice {
    padding-top: $section_spacing - 8px;
    padding-bottom: 0;
    margin: 0 auto;

    .content {
        max-width: 1170px;
        margin: 0 auto;
    }

    h3 {
        text-align: center;
        font-size: 22px;
        margin-bottom: 75px;

        color: $dark-grey;
    }

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media (max-width: $break_tablet) {
        padding: 50px 20px 0 20px;

        h3 {
            margin-bottom: 40px;
        }

        ul {
            max-width: 780px;
            margin: 0 auto;
        }
    }

    @media (max-width: $break_mobile) {
        &:after {
            display: block;
            border-bottom: 1px solid $light-grey;
            content: '';
        }

        h3 {
            margin-bottom: 20px;
        }
    }
}
