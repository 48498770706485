@import '../../../../scss/settings';

#register-form-modal {
    display: none;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(0, 0, 0, 0.6);

    z-index: $z-index-modal-overlay;

    padding: 20px;

    &.visible {
        display: flex;

        opacity: 0;

        .modal-container {
            opacity: 0;
        }

        &.show {
            opacity: 1;

            .modal-container {
                opacity: 1;
            }
        }

        &.fade-in {
            transition: opacity 0.3s ease-in-out;

            .modal-container {
                transition: opacity 0.3s ease-in-out;
                transition-delay: 0.2s;
            }
        }

        &.fade-out {
            transition-delay: 0.3s;
            transition: opacity 0.3s ease-in-out;

            .modal-container {
                transition: opacity 0.3s ease-in-out;
            }
        }
    }

    .modal-close-trigger {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: $z-index-modal-overlay + 100;
    }

    .modal-container {
        display: flex;
        max-width: 910px;

        margin: auto;

        background: $white;

        border-radius: 5px;
        box-shadow: 0 11px 15px -7px rgba(50, 59, 74, 0.2), 0 9px 46px 8px rgba(50, 59, 74, 0.12),
            0 24px 38px 3px rgba(50, 59, 74, 0.14);

        overflow: hidden;

        z-index: $z-index-modal-overlay + 200;

        h3 {
            font-size: 24px;
            font-family: $font-title;
            font-weight: 600;
        }

        .register-info {
            display: flex;
            flex-direction: column;

            flex-basis: 500px;

            padding: 50px;

            background-image: linear-gradient(39deg, #1c1c1f, $charcoal);

            font-size: 18px;
            color: #a5a5a5;

            h3 {
                color: #e5e5e5;
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 10px;
            }

            .free-use {
                font-weight: 600;
            }

            .register-feature-image {
                max-width: 100%;
                margin-bottom: 20px;

                border-radius: 2px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

                opacity: 0.8;
            }

            .float-bottom {
                margin-top: auto;
            }

            .testimonial {
                display: flex;

                .testimonial-image {
                    flex-shrink: 0;

                    width: 40px;
                    height: 40px;

                    margin-right: 10px;

                    background: url('./testimonial-image.png');
                    background-size: 100% 100%;
                }

                .testimonial-quote {
                    flex-grow: 1;

                    .quote-attribution {
                        font-size: 14px;
                    }
                }
            }
        }

        .register-form {
            flex-basis: 410px;
            flex-shrink: 0;

            padding: 40px;

            h3 {
                margin-bottom: 30px;
            }

            .RegisterForm {
                padding: 0;
            }
        }
    }

    @media (max-width: $break_mobile) {
        .modal-container {
            display: block;

            .register-info {
                display: none;
            }
        }
    }
}
