@import '../../../../../scss/settings';

.ColumnFeature {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-container {
        width: 85%;
        margin-bottom: 20px;

        text-align: center;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    h2 {
        text-align: center;
        font-size: 22px;
        font-family: $font-title;
        font-weight: $font-weight-semibold;

        margin-bottom: 22px;
        color: $charcoal;
    }

    &.image-location-top {
        h2 {
            margin-bottom: 10px;
        }
    }

    .summary {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 17px;
        color: $dark-grey;
    }

    .details {
        font-size: 15px;
        line-height: $font-size-3;
        color: $dark-grey;

        ul {
            padding-left: 17px;
            list-style: disc;

            li {
                margin-bottom: 8px;
            }
        }
    }

    &.text-align-center {
        h2 {
            text-align: center;
        }

        .summary {
            text-align: center;
        }
    }

    &.text-align-left {
        h2 {
            text-align: left;
        }

        .summary {
            text-align: left;
        }
    }

    &.text-body-larger {
        .summary {
            font-size: 19px;
            line-height: 1.38;
        }
    }

    &.image-pull-up {
        .image-container {
            img {
                margin-top: -80px;
            }
        }
    }

    &.image-fixed-height {
        .image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 450px;
        }
    }

    // Tablet only
    @media (min-width: $break_mobile + 1) and (max-width: $break_tablet) {
        &.image-fixed-height,
        &.image-pull-up {
            .image-container {
                margin: 0;
                padding: 20px;
                padding-bottom: 40px;
                margin-bottom: 10px;

                img {
                    margin: 0;
                }
            }
        }

        &.image-fixed-height {
            .image-container {
                height: 465px;
                max-width: 90%;

                img {
                    max-width: 300px;
                }
            }
        }

        &.image-shrink-tablet {
            img {
                max-width: 200px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        h2,
        .feature-info {
            max-width: none;
            width: auto;
        }

        &.image-fixed-height,
        &.image-pull-up {
            .image-container {
                height: auto;
                width: auto !important;
                margin: 0;
                padding: 20px;
                padding-bottom: 20px;

                img {
                    margin: 0;
                }
            }
        }

        &.column-2 {
            .image-container {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        &.image-shrink-tablet {
            img {
                max-width: 200px;
            }
        }

        &.text-align-center {
            h2 {
                text-align: left;
            }

            .summary {
                text-align: left;
            }
        }

        &.text-body-larger {
            .summary {
                font-size: 18px;
            }
        }
    }
}
