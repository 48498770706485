@import '../../../../../scss/settings';

.GuideTextBlockSlice {
    margin-top: 9rem;

    position: relative;

    font-size: 2rem;
    line-height: 1.5;

    color: #6f6f72;

    .guide-text-title {
        margin-bottom: 2rem;

        font-size: 3.2rem;
        font-family: $font-title;
        line-height: 1.18;
        color: $charcoal;

        font-weight: 600;
    }

    .text-explanation {
        color: $charcoal;

        p {
            margin-bottom: 1.9rem;
        }

        a {
            color: $charcoal;
        }

        ul {
            li {
                padding-left: 0.5rem;
                margin-left: 2rem;

                margin-bottom: 0.5rem;

                list-style: disc;
            }
        }
    }

    .text-block-image {
        margin-top: 1.5rem;

        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    &:first-of-type {
        margin-top: 0;
    }

    @media (max-width: $break_laptop) {
        .guide-text-title {
            line-height: 1.33;

            h2 {
                font-size: 3rem;
            }

            h3 {
                font-size: 2.4rem;
            }
        }

        .text-explanation {
            font-size: 1.8rem;
            line-height: 1.6;
        }
    }

    @media (max-width: $break_mobile) {
        padding-left: 15px;
        padding-right: 15px;

        margin-top: 4.5rem;

        font-size: 18px;

        .guide-text-title {
            margin-bottom: 1rem;
        }

        .text-block-image {
            img {
                width: 100%;
                border-radius: 0;
            }
        }
    }
}
