@import '../../../scss/settings';

.Section.InspirationDetailsSection {
    .content {
        display: flex;
    }

    .Video {
        max-width: 100%;

        background: $light-grey;
        box-shadow: 0 0 20px 0 $inspiration-image-shadow-color-1, 0 4px 8px 0 $inspiration-image-shadow-color-2;

        .image {
            width: 100%;
        }
    }

    .macbook-image {
        display: none;
    }

    .mobile-title,
    .desktop-title {
        margin-bottom: 12px;

        color: $charcoal;
        font-family: $font-title;
    }

    .TemplateAnchorButton {
        margin-top: 17px;
    }

    p {
        color: $dark-grey;
    }

    // Larger than tablet
    @media (min-width: $break_tablet + 1) {
        .content {
            align-items: center;

            max-width: 1140px;

            padding-top: 40px;
            padding-bottom: 40px;
        }

        .image-section {
            display: flex;
            flex-shrink: 1;
            flex-grow: 1;

            padding-right: 95px;
        }

        .image-container {
            position: relative;
            padding-top: 20px;
            padding-bottom: 20px;

            .background-image {
                position: absolute;
                right: 0;
                transform: translate3d(100px, -45px, 0);

                height: 360px;
                width: 200px;

                z-index: -1;
            }

            .Video {
                width: 488px;
                height: 274px;
            }
        }

        .details {
            flex-shrink: 1;

            padding-right: 70px;
            padding-left: 95px;

            min-width: 485px;
        }

        &.image-left {
            .content {
                flex-direction: row-reverse;
            }

            .details {
                padding-right: 95px;
                padding-left: 70px;
            }

            .image-section {
                padding-right: 0;
                padding-left: 95px;
                justify-content: flex-end;
            }

            .image-container {
                .background-image {
                    right: auto;
                    left: 0;
                    transform: translate3d(-100px, -45px, 0);
                }

                .overlay-image {
                    position: absolute;
                    width: 105px;
                    height: 108px;
                    right: -77px;
                    bottom: 0;
                }
            }
        }

        .mobile-title,
        .desktop-title {
            font-size: $font-size-4;
            line-height: 30px;
        }

        .mobile-title {
            display: none;
        }

        p {
            font-size: 18px;
            line-height: 30px;
        }

        &.feature {
            .details {
                padding-right: 40px;
                padding-left: 90px;
            }

            .image-container {
                padding-top: 0;
                padding-bottom: 0;

                .macbook-image {
                    display: block;
                    position: absolute;
                    width: 639px;
                    z-index: -1;
                    top: -39px;
                    left: -76px;
                }

                .Video {
                    width: 488px;
                    height: 274px;
                }
            }
        }
    }

    // Larger than desktop
    @media (min-width: $break_desktop + 1) {
        &.feature {
            .image-container {
                .macbook-image {
                    width: 777px;
                    top: -47px;
                    left: -90px;
                }

                .Video {
                    width: 596px;
                    height: 336px;
                }
            }
        }
    }

    // Desktop and smaller
    @media (max-width: $break_desktop) {
        .desktop-title {
            font-size: 21px;
        }

        p {
            font-size: $font-size-5;
        }
    }

    // Tablet and smaller
    @media (max-width: $break_tablet) {
        padding-right: 20px;
        padding-left: 20px;

        &.image-left .content,
        .content {
            flex-direction: column;
            align-items: flex-start;

            max-width: 707px;

            padding-top: 40px;
            padding-bottom: 40px;
        }

        .mobile-title {
            order: 1;
            margin-bottom: 20px;
            font-size: 21px;
        }

        .TemplateAnchorButton {
            margin-top: 25px;
        }

        p {
            font-size: 18px;
            line-height: 30px;
        }

        .image-section {
            order: 2;
            margin-bottom: 17px;

            .background-image {
                display: none;
            }

            .overlay-image {
                display: none;
            }
        }

        .details {
            order: 3;
            max-width: 100%;
        }

        .desktop-title {
            display: none;
        }

        .mobile-title {
            display: block;
        }
    }

    // Mobile only
    @media (max-width: $break_mobile) {
        .mobile-title {
            margin-bottom: 12px;
        }

        .TemplateAnchorButton {
            margin-top: 17px;
        }

        &.image-left .content,
        .content {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .image-section {
            margin-bottom: 17px;
        }
    }
}
