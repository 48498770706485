@import '../../../scss/settings';

.Plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: $white;

    text-align: left;
    color: $charcoal;

    .hidden {
        display: none;
    }

    .title {
        h3 {
            display: inline-block;

            margin-bottom: 1rem;
            font-size: 1.8rem;
            font-weight: 600;
        }

        .save-percent {
            position: relative;
            display: inline-block;

            top: -0.2rem;
            margin-left: 1rem;
            padding: 0.3rem 0.6rem 0.3rem 0.6rem;
            border-radius: 0.4rem;
            background-color: #edf8ee;
            color: #4eb85a;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }

    .price {
        position: relative;
        font-weight: bold;
        margin-bottom: 4px;

        .dollar {
            font-size: 3.6rem;
        }

        .frequency {
            font-size: 1.4rem;
        }
    }

    .disclaimer {
        min-height: 36px;
        color: #6E6E71;
        font-size: 1.2rem;
    }

    .detail-switch {
        min-height: 3rem;
        margin-bottom: 26px;

        font-size: 1.4rem;

        .volume-select-container {
            display: inline-block;
            position: relative;

            margin-left: 1rem;
            margin-right: 1rem;

            border: 1px solid $plan-divider-color;
            border-radius: 3px;

            background-image: url('./dropdown-arrow.svg');
            background-repeat: no-repeat;
            background-position: center right;

            select {
                display: block;
                width: 100%;
                height: 2.5rem;
                margin: 0;
                padding: 0 2.5rem 0.2rem 1rem;

                border: 0;
                outline: 0;
                background: none;

                color: $black;
                font-family: $font-body-alt;
                font-size: 14px;

                cursor: pointer;

                -moz-appearance: none;
                -webkit-appearance: none;
            }
        }
    }

    .feature-details {
        margin-top: auto;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    .feature-list {
        margin-bottom: 4rem;

        li {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            margin-bottom: 0.6rem;

            font-size: 1.4rem;
            line-height: 1.7;

            &:before {
                display: block;
                flex-shrink: 0;

                width: 1.6rem;
                height: 1.6rem;

                margin-top: 0.3rem;
                margin-right: 1.7rem;

                background: url('./feature-tick.svg');
                background-size: 100% 100%;

                content: '';
            }
        }
    }

    .Button {
        width: 100%;

        padding-right: 10px;
        padding-left: 10px;
        margin-top: auto;

        text-align: center;
    }

    .footer {
        padding-top: 3rem;
        height: 1rem;

        font-size: 1.4rem;
        text-align: center;

        color: $charcoal;

        a {
            color: $charcoal;
            white-space: nowrap;
        }
    }

    @media (max-width: 915px) {
        .title {
            .save-percent {
                display: none;
            }
        }
    }
}
