@import '../../../scss/settings';

.BlogPostBackLinks {
    display: flex;
    flex-direction: column;
    align-items: center;

    .back-links-heading-container {
        font-family: $font-blog-body;
        text-align: center;
        font-size: 18px;
        font-style: italic;
        line-height: 1.11;
        color: $plan-enterprise-color;
        max-width: 850px;
        padding: 0 50px;
        width: 100%;

        @media (max-width: $break_mobile) {
            padding: 0;
        }
    }

    .back-links-heading {
        padding: 20px 0;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
    }

    .GridSection {
        margin: 55px 0 154px;

        .content {
            justify-content: flex-start;
        }

        @media (max-width: $break_tablet) {
            margin: 55px 0 55px;
        }

        @media (max-width: $break_mobile) {
            margin: 10px 0 30px;
        }
    }
}
