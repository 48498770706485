@import '../../../scss/settings';

.TextContent {
    font-size: 16px;
    line-height: 1.4;

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    p {
        margin: 1em 0;
    }

    h1,
    h2,
    h3 {
        margin: 1em 0 0.2em 0;
    }

    h1 {
        font-family: $font-title;
        font-size: 2em;
        font-weight: normal;
    }

    h2 {
        font-size: 1.5em;
        font-weight: 600;
    }

    h3 {
        font-size: 1.2em;
        font-weight: 600;
    }

    ol,
    li {
        padding-left: 1em;

        li {
            padding-left: 0.8em;
        }
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: circle;
    }
}
