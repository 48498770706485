@import '../../../scss/settings';

$transition_duration: 0.2s;

.InspirationCategoryPanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 440px;

    text-decoration: none;
    color: $charcoal;

    background: $charcoal;

    border-radius: 6px;

    overflow: hidden;

    box-shadow: 0 0 10px rgba(17, 24, 49, 0.1);

    .label {
        font-size: 18px;
        color: $white;

        opacity: 0;
        transform: translate(0, 8px);
        transition: opacity $transition_duration linear, transform $transition_duration ease-out;
    }

    .segment-header {
        position: relative;
        height: 200px;

        flex-shrink: 0;

        background-color: #cccccc;
        background-size: auto 100%;
        background-position: center;

        border-radius: 6px 6px 0 0;

        .segment-header-overlay {
            position: absolute;

            display: flex;
            align-items: center;
            justify-content: center;

            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background-color: rgba(0, 0, 0, 0.4);

            transition: background-color $transition_duration linear;
        }

        .segment-header-overlay-content {
            text-align: center;

            transition: transform $transition_duration ease-in-out;

            h2 {
                margin-top: 25px;
                margin-bottom: 0;

                font-size: 30px;
                font-weight: 600;
                color: $white;
            }
        }
    }

    .testimonial-container {
        display: flex;
        height: 100%;
        background: $white;
    }

    .Testimonial {
        padding: 20px;
        width: 100%;
    }

    &:hover {
        .segment-header-overlay-content {
            transform: translate(0, -14px);
        }

        .label {
            opacity: 1;
            transform: translate(0, 0);
        }

        .segment-header-overlay {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }

    @media (max-width: $break_tablet) {
        .segment-header {
            height: 175px;
            background-size: 100% auto;
        }
    }

    @media (max-width: $break_mobile) {
        height: auto;

        .segment-header {
            height: 150px;
            background-size: 100% auto;
        }

        .testimonial-container {
            display: block;
            height: auto;
        }
    }
}
