@import '../../../scss/settings';

.Tags {
    display: flex;
    flex-wrap: wrap;

    .tag-link {
        display: block;
        color: #f4511c;
        text-decoration: underline;
        margin-right: 15px;
        font-family: $font-blog-body;
        font-size: 16px;
        line-height: 1.25;
        text-transform: capitalize;

        @media (max-width: $break_mobile) {
            font-size: 14px;
        }
    }
}
