.Video {
    video,
    img {
        width: 100%;
    }

    video {
        border-radius: 3px;
        overflow: hidden;
    }
}
