@import '../../../scss/settings';

.ReleaseNotesEntry {
    margin-bottom: 60px;

    font-size: 18px;
    line-height: 1.5;

    header {
        margin-bottom: 14px;

        h2 {
            font-size: $font-size-3;
            font-family: $font-title;
        }

        .release-date {
            color: $mid-grey;
        }
    }

    h3 {
        font-size: 20px;
        margin-top: 1em;
        font-weight: 600;
    }

    p {
        margin-top: 0.2em;
        margin-bottom: 0.5em;
    }

    ol,
    ul {
        margin: 0;
        padding-left: 1em;
        margin-top: 0.2em;
        margin-bottom: 0.5em;
    }

    ul {
        list-style: disc;
    }
}
