@import '../../../scss/settings';

.ReleaseNotesNavigation {
    font-size: 18px;

    color: $white;

    h3 {
        font-weight: 600;
        margin-bottom: 0.5em;
    }

    a {
        text-decoration: none;
        color: $mid-light-gray;

        &.selected {
            color: $white;
        }
    }

    @media (max-width: $break_tablet) {
        font-size: 16px;
    }

    @media (max-width: $break_mobile) {
        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            padding-bottom: 20px;

            a {
                margin-right: 20px;
                white-space: nowrap;
            }
        }
    }
}
