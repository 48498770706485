@import '../../../scss/settings';

.GuideStep {
    position: relative;
    padding: 38px 44px 52px 44px;

    font-size: $font-size-4;
    line-height: 1.5;

    color: #6f6f72;

    .guide-step-title {
        margin-bottom: 0.3em;

        font-family: $font-title;
        font-size: $font-size-2;
        line-height: $font-size-1;
        color: $charcoal;
    }

    .step-number {
        position: absolute;
        right: 100%;

        margin-top: 3px;
        margin-right: -14px;

        font-size: 24px;
        line-height: 40px;
        text-align: right;

        white-space: nowrap;
    }

    .step-explanation {
        margin-bottom: 1.5em;
    }

    .step-image {
        img {
            width: 100%;
        }
    }

    .step-video {
        iframe {
            width: 100%;
            height: 423px;
        }
    }

    .step-how-to {
        margin-top: 1.5em;
        max-width: 600px;

        font-size: 18px;
    }

    .TemplateAnchorButton {
        margin-top: 20px;
    }

    &:last-of-type {
        padding-bottom: 0;
    }

    @media (max-width: $break_tablet) {
        padding-top: 25px;
        padding-bottom: 30px;

        .guide-step-title {
            font-size: $font-size-3;
        }

        .step-number {
            position: relative;
            right: auto;

            margin-top: 0;
            margin-right: 0;

            font-size: 24px;
            text-align: left;
        }
    }

    @media (max-width: $break_mobile) {
        padding: 20px 20px 30px 20px;

        .step-video {
            iframe {
                height: 400px;
            }
        }
    }

    @media (max-width: 650px) {
        .step-video {
            iframe {
                height: 350px;
            }
        }
    }

    @media (max-width: 600px) {
        .step-video {
            iframe {
                height: 300px;
            }
        }
    }

    @media (max-width: 550px) {
        .step-video {
            iframe {
                height: 250px;
            }
        }
    }

    @media (max-width: 500px) {
        .step-video {
            iframe {
                height: 200px;
            }
        }
    }
}
