@import '../../../../../scss/settings';

.GuideExampleTooltip {
    position: absolute;

    width: 550px + 60px;
    padding: 30px;

    border-radius: 8px;

    box-shadow: 0 11px 15px -7px rgba(50, 59, 74, 0.2), 0 9px 46px 8px rgba(50, 59, 74, 0.12),
        0 24px 38px 3px rgba(50, 59, 74, 0.14);

    color: $charcoal;
    font-size: 18px;
    background: $white;

    opacity: 0;

    transition: opacity 0.1s ease-in-out;

    pointer-events: none;

    z-index: 99999999;

    .media-container {
        background-color: #E7E8ED;
    }

    .mobile-tooltip-header {
        display: none;

        padding-bottom: 1.6rem;

        text-align: right;

        .mobile-close-button {
            width: 24px;
            height: 24px;

            border: 0;
            font-size: 0;

            background: url('./tooltip-button-close-mobile.svg');
        }
    }

    .media-container {
        width: 100%;
        height: 310px;
        overflow: hidden;

        video {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #eee;
        }

        img {
            width: 100%;
            border-radius: 3px;
        }
    }

    .tooltip-text {
        margin-top: 14px;

        h4 {
            margin-bottom: 4px;
            font-size: 20px;
            font-weight: bold;
        }
    }

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    &:after {
        position: absolute;
        display: block;

        left: calc(50% - 10px);
        bottom: -10px;

        width: 20px;
        height: 20px;

        background: $white;

        transform: rotate(45deg);

        content: '';
    }

    @media (max-width: $break_laptop) {
        width: 400px + 40px;
        padding: 20px;

        font-size: 16px;

        .media-container {
            height: 227px;
        }

        .tooltip-text {
            h4 {
                font-size: 18px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        position: fixed;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        padding: 20px;

        .mobile-tooltip-header {
            display: block;
        }

        .media-container {
            height: auto;
        }
    }
}
