@import '../../../scss/settings';

.GridSection {
    > .content {
        display: flex;
        flex-wrap: wrap;
    }

    .GridItem {
        width: calc(33.33% - 30px);
        margin: 15px;
    }

    &.center {
        > .content {
            justify-content: center;
        }
    }

    @media (max-width: $break_tablet) {
        > .content {
            padding-left: 10px;
            padding-right: 10px;
        }

        .GridItem {
            width: calc(50% - 20px);
            margin: 10px;
        }
    }

    @media (max-width: $break_mobile) {
        .GridItem {
            width: 100%;
        }
    }
}
