@import '../../../scss/settings';

.BrowserSupportPage {
    text-align: center;

    .Section .content {
        width: 80%;
    }

    .hero-image {
        margin-top: 115px;
        width: 255px;
        height: auto;
    }

    .PageTitle {
        font-size: 40px;
        padding: 0.3em 20px 0.2em 20px
    }

    .page-description {
        font-family: "Inter", $font-basic;
        font-size: 18px;
        font-weight: 600;
    }

    .Section.content-section .content {
        padding: 58px 0 140px;
    }

    table {
        margin: 0 auto;
        max-width: 500px;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        tr {
            border-bottom: 1px solid #e0e0e0;
        }

        th, td {
            font-family: "Inter", $font-basic;
            font-size: 14px;
            text-align: left;
            padding: 10px;
        }

        th {
            font-weight: bold;
        }

        td:first-child {
            position: relative;
            vertical-align: middle;
            padding-left: 45px;

            .browser-logo {
                position: absolute;
                top: 7px;
                left: 10px;
            }
        }

        th:last-child,
        td:last-child {
            text-align: right;
        }
    }

    @media (max-width: $break_mobile) {
        .Section.content-section .content {
            padding: 30px 0 70px;
        }

        .PageTitle {
            font-size: 30px;
        }

        .page-description {
            font-size: 15px;
        }

        table {
            table-layout: auto;
        }
    }

}
