@import '../../../scss/settings';

.Testimonial {
    text-align: center;
    color: #6f6f72;

    .quote {
        margin-bottom: 16px;

        font-size: $font-size-5;
        line-height: 1.25;

        quotes: '\201c''\201d';

        &:before {
            display: inline;
            content: open-quote;
        }

        &:after {
            display: inline;
            content: close-quote;
        }
    }

    .image {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6px;

        .UserImage {
            width: 50px;
            height: 50px;
        }
    }

    &.large {
        max-width: 800px;
        margin: 0 auto;

        .quote {
            font-size: $font-size-2;
        }

        .image {
            font-size: $font-size-2;
            margin-bottom: 12px;

            .UserImage {
                width: 60px;
                height: 60px;
            }
        }

        .UserName {
            font-size: $font-size-5;
        }
    }

    &.fixed-height {
        .quote {
            height: 45px;
        }
    }

    &.medium {
        .quote {
            font-size: $font-size-3;
            margin-bottom: 10px;
        }

        .UserName {
            font-size: $font-size-5;
        }
    }

    &.align-left {
        text-align: left;

        .user {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .image {
                margin-right: 12px;
                margin-bottom: 0;

                .UserImage {
                    width: 40px;
                    height: 40px;
                }
            }

            .name {
                display: inline-block;

                &:after {
                    content: ', ';
                    margin-right: 0.2em;
                }
            }

            .meta {
                display: inline-block;
            }

            .image {
                align-items: flex-start;
            }

            .company {
                margin-top: 2px;

                img {
                    max-height: 12px;
                }
            }
        }
    }

    @media (max-width: $break_tablet) {
        &.medium {
            .quote {
                font-size: $font-size-4;
                margin-bottom: 10px;
            }

            .UserName {
                font-size: 14px;
            }
        }

        &.large {
            .quote {
                font-size: $font-size-3;
            }
        }
    }

    @media (max-width: $break_mobile) {
        &.large {
            .quote {
                font-size: $font-size-4;
            }
        }

        &.fixed-height {
            .quote {
                height: auto;
            }
        }
    }
}
