@import 'fonts';
@import 'colors';
@import 'util';

$max-page-width: 1440px;

$break_laptop: 1366px;
$break_desktop: 1200px;
$break_tablet: 1025px;
$break_tablet_portrait: 800px;
$break_mobile: 767px;

// Home page section spacing
$section_spacing: 100px;

// MATERIAL DESIGN
// Animation Curves
//      entering
$deceleration-curve: cubic-bezier(0, 0, 0.2, 1);
//      permanently leaving
$acceleration-curve: cubic-bezier(0.4, 0, 1, 1);
//      leaving but entering again
$sharp-curve: cubic-bezier(0.4, 0, 0.6, 1);

// Animation timing
//      Mobile
$large-duration: 375ms;
// $mobileEnterDuration: 225ms;
$enter-duration: 300ms;
$leave-duration: 195ms;

$mobile-horizontal-padding: 30px;

$z-index-header: 500;
$z-index-modal-overlay: 2000;
