$white: #ffffff;
$light-grey: #f2f2f2;
$light-light-grey: #e7e7e7;
$off-white: #cfcdd2;
$mid-light-gray: #cacaca;
$mid-grey: #929292;
$mid-dark-grey: #e4e4e4;
$mid-light-grey: #8c8b9a;
$dark-grey: #6e6e71;
$darker-grey: #484848;
$charcoal: #31303a;
$green-900: #4eb85a;
$iphone-header-black: #171719;
$black: #000000;

$brand-color: #f4511c;
$brand-purple-color: #4f5eff;

$header-brand-name-color: $charcoal;
$header-title-color: $charcoal;
$header-phrase-color: #818181;
$header-light-link-color: $charcoal;

$secondary-button-color: #6f6f72;
$secondary-button-border-color: #909090;

$modal-primary-color: $charcoal;
$modal-secondary-color: #4c4c4c;

$plan-divider-color: #d8d8d8;

$plan-enterprise-color: #909090;

$review-border: #d6d6d8;
$grid-border: #d9d9d9;

$form-input-placeholder-color: #cbcbcb;
$form-input-border-color: #cbcbcb;
$form-input-background-color: $white;
$form-input-focus-border-color: #6e6e71;

$form-success-color: #56a24a;
$form-warn-color: #e50c0c;
$form-error-color: #e50c0c;

$form-input-text-color: $black;
$form-secondary-text-color: $mid-grey;

$section-divider-color: #e9e9e9;

$blog-brand-color: $brand-purple-color;
$blog-header-motto-color: #909090;

// Inspiration pages
$inspiration-image-shadow-color-1: rgba(17, 24, 49, 0.1);
$inspiration-image-shadow-color-2: rgba(0, 0, 0, 0.15);
