@import '../../../scss/settings';

.PageTitle {
    font-family: $font-title;
    font-size: $font-size-1;
    font-weight: normal;
    padding: 0.8em 20px 0.4em 20px;
    color: $header-title-color;

    text-align: center;

    &.light {
        color: $white;
    }

    &.large {
        font-size: 48px;

        @media (max-width: $break_desktop) {
            font-size: $font-size-1;
        }

        @media (max-width: $break_mobile) {
            font-size: $font-size-1;
        }
    }

    &.sans-serif {
        font-family: $font-body;
        font-weight: 600;
    }

    &.align-left {
        text-align: left;
    }

    &.heavy {
        font-weight: $font-weight-semibold;
    }

    @media (max-width: $break_desktop) {
        font-size: $font-size-2;
    }

    @media (max-width: $break_tablet) {
        font-size: $font-size-2;
    }
}
