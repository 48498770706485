@import '../../../scss/settings';

.SuperGuide {
    overflow-x: hidden;

    .Header {
        background: none;
    }

    .guide-header {
        background: $light-grey;

        .intro {
            font-size: 21px;
            line-height: 1.42;
            color: #6f6f72;

            .guide-step-title {
                font-family: $font-title;
                font-size: $font-size-2;
            }
        }

        .guide-title {
            padding: 0;
            margin-bottom: 20px;
            font-size: $font-size-2;
        }

        > .content {
            max-width: 840px;
            padding: 68px 44px 54px 44px;
        }
    }

    &.no-navigation {
        .guide-header {
            > .content {
                padding-top: 34px;
            }
        }
    }

    .guide-content {
        margin: 0 auto 0 auto;
        max-width: 840px;

        font-size: 21px;
        line-height: 1.5;

        ol,
        li {
            padding-left: 1em;

            li {
                padding-left: 0.8em;
            }
        }

        ol {
            list-style: decimal;
        }

        ul {
            list-style: circle;
        }
    }

    .RegisterFormFooterSection {
        margin-top: 95px;
    }

    @media (max-width: $break_tablet) {
        .guide-header {
            > .content {
                padding-top: 30px;

                .intro {
                    font-size: $font-size-4;
                }
            }
        }
    }

    @media (max-width: $break_mobile) {
        font-size: $font-size-4;

        .guide-header {
            > .content {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}
