@import '../../../scss/settings';

.Home2019PageHeader {
    background: $white;

    .AsideSection > .content {
        overflow: visible;

        > section {
            flex-basis: 360px;
            width: 360px;
        }
    }

    .PageTitle {
        padding-top: 40px;
        padding-bottom: 25px;

        color: $charcoal;

        font-size: 42px;
        line-height: 1.18;
    }

    .PageIntroduction {
        padding-bottom: 25px;

        font-size: 22px;
        line-height: 1.33;

        color: $dark-grey;
    }

    .testimonial-container {
        margin-top: 30px;

        &:before {
            display: block;
            content: '';
            width: 100px;
            border-top: 1px solid $light-light-grey;
        }

        .Testimonial {
            margin-top: 25px;

            .company {
                filter: grayscale(100%);
            }
        }
    }

    .hero-container {
        transform: translate3d(135px, 27px, 0);

        > img {
            padding-left: 20%;
        }
    }

    .spacer {
        padding-bottom: 90%;
    }

    // Tablet only
    @media (min-width: $break_mobile + 1) and (max-width: $break_tablet) {
        .hero-container {
            transform: translate3d(275px, 10px, 0);

            > img {
                padding-left: 10%;
            }
        }

        .spacer {
            padding-bottom: 102%;
        }
    }

    // Mobile
    @media (max-width: $break_mobile) {
        padding-top: 8px;
        padding-bottom: 20px;

        .AsideSection > .content {
            display: block;

            > section {
                flex-basis: auto;
                width: auto;
            }
        }

        .section-content {
            margin-top: 0;
        }

        .PageTitle {
            padding-top: 0;

            font-size: 34px;
        }

        .PageIntroduction {
            font-size: 20px;
        }

        .spacer {
            display: none;
        }

        .hero-container {
            transform: none;
            padding: 40px 50px 20px 20px;
            max-height: none;

            > img {
                padding: 0;
            }
        }
    }
}
