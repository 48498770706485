@import 'fonts';

/*
 Body font definition
 */
@font-face {
    font-family: Source Sans;
    font-style: normal;
    font-weight: normal;
    src: url('../font/SourceSansPro-Regular.ttf');
}

@font-face {
    font-family: Source Sans;
    font-style: normal;
    font-weight: bold;
    src: url('../font/SourceSansPro-Bold.ttf');
}

@font-face {
    font-family: Source Sans;
    font-style: normal;
    font-weight: $font-weight-semibold;
    src: url('../font/SourceSansPro-Semibold.ttf');
}

@font-face {
    font-family: Source Sans;
    font-style: italic;
    font-weight: normal;
    src: url('../font/SourceSansPro-Italic.ttf');
}

@font-face {
    font-family: Source Sans;
    font-style: italic;
    font-weight: bold;
    src: url('../font/SourceSansPro-BoldItalic.ttf');
}

/*
 Body font fallback definition - Helvetica & Arial
 - This is done to adjust the size of fallback fonts, and reduce layout shifts
 - For now, only override fallback fonts used for buttons, as this is the most obvious
   layout shifts during page load
 */

@font-face {
    font-family: 'Source Sans Fallback - Helvetica';
    font-style: normal;
    font-weight: normal;
    src: local(Helvetica);
    size-adjust: 92%;

}

@font-face {
    font-family: 'Source Sans Fallback - Helvetica';
    font-style: normal;
    font-weight: $font-weight-semibold;
    src: local(Helvetica);
    size-adjust: 96%;
}

@font-face {
    font-family: 'Source Sans Fallback - Arial';
    font-style: normal;
    font-weight: normal;
    src: local(Arial);
    size-adjust: 92%;

}

@font-face {
    font-family: 'Source Sans Fallback - Arial';
    font-style: normal;
    font-weight: $font-weight-semibold;
    src: local(Arial);
    size-adjust: 92%;
}

/*
 Title font definition - Tiempos
 */

@font-face {
    font-family: 'Tiempos';
    font-weight: normal;
    src: url('../font/TiemposTextWeb-Medium.woff2'), url('../font/TiemposTextWeb-Medium.woff'),
        url('../font/TiemposTextWeb-Medium.eot');
}

@font-face {
    font-family: 'Tiempos';
    font-style: italic;
    font-weight: normal;
    src: url('../font/TiemposTextWeb-RegularItalic.woff2'), url('../font/TiemposTextWeb-RegularItalic.woff'),
        url('../font/TiemposTextWeb-RegularItalic.eot');
}

@font-face {
    font-family: 'Tiempos';
    font-weight: $font-weight-semibold;
    src: url('../font/TiemposTextWeb-Semibold.woff2'), url('../font/TiemposTextWeb-Semibold.woff'),
        url('../font/TiemposTextWeb-Semibold.eot');
}

@font-face {
    font-family: 'Domaine Display Narrow';
    font-weight: $font-weight-semibold;
    src: url('../font/DomaineDispNar-Semibold.otf');
}

@font-face {
    font-family: 'Domaine Display Narrow';
    font-weight: $font-weight-semibold;
    font-style: italic;
    src: url('../font/DomaineDispNar-SemiboldItalic.otf');
}

@font-face {
    font-family: 'Meta Serif OT';
    font-weight: normal;
    src: url('../font/MetaSerifOT-Book.otf');
}

@font-face {
    font-family: 'Meta Serif OT';
    font-weight: bold;
    src: url('../font/MetaSerifOT-Bold.otf');
}

@font-face {
    font-family: 'Meta Serif OT';
    font-style: italic;
    font-weight: normal;
    src: url('../font/MetaSerifOT-BookItalic.otf');
}

@font-face {
    font-family: 'Meta Serif OT';
    font-style: italic;
    font-weight: bold;
    src: url('../font/MetaSerifOT-BoldItalic.otf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: url('../font/Inter-Regular.woff2'), url('../font/Inter-Regular.woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: $font-weight-semibold;
    src: url('../font/Inter-SemiBold.woff2'), url('../font/Inter-SemiBold.woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    src: url('../font/Inter-Bold.woff2'), url('../font/Inter-Bold.woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: normal;
    src: url('../font/Inter-Italic.woff2'), url('../font/Inter-Italic.woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: $font-weight-semibold;
    src: url('../font/Inter-SemiBoldItalic.woff2'), url('../font/Inter-SemiBoldItalic.woff');
}
