@import '../../../scss/settings';

.TemplateAnchorButton {
    display: flex;
    align-items: center;

    width: 360px;

    border: 1px solid $mid-light-gray;
    border-radius: 3px;

    padding: 14px 15px;

    color: $charcoal;
    text-decoration: none;
    outline: none;

    .board-icon {
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        margin-right: 10px;
        overflow: hidden;
    }

    .text {
        flex-grow: 1;

        padding-bottom: 3px;

        font-weight: $font-weight-semibold;
        font-size: 18px;
    }

    .chevron {
        flex-shrink: 0;
        width: 9px;
        height: 13px;
        margin-bottom: 2px;
        margin-left: 10px;

        background-image: url('./icon/chevron-right.svg');
    }

    @media (max-width: $break_desktop) {
        width: 320px;

        .text {
            font-size: 16px;
        }
    }

    @media (max-width: $break_tablet) {
        width: 100%;
    }
}
