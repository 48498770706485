@import '../../../scss/settings';

.Home2019Page {
    .Header {
        background: $white;
    }

    .header-scroll-spacer {
        height: 96px;
    }

    // Tablet only
    @media (min-width: $break_mobile + 1) and (max-width: $break_tablet) {
        .Header > .header-content {
            padding-left: 25px;
            padding-right: 25px;
        }

        .Section > .content {
            padding-left: 10px;
            padding-right: 10px;
        }

        .GridSection > .content {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    @media (max-width: $break_mobile) {
        .header-scroll-spacer {
            height: 20px;
        }

        .Header {
            background: $charcoal;
        }
    }
}
