@import '../../../scss/settings';

.Guide2020PageHeader {
    padding-top: 16.2rem;
    margin-bottom: 12rem;

    .Breadcrumb {
        .BreadcrumbEntry {
            a {
                color: #808080;
            }

            &.inactive a {
                color: $charcoal;
            }
        }
    }

    .PageTitle {
        padding-top: 3.2rem;
        padding-bottom: 2.3rem;

        font-size: 5.6rem;
        line-height: 1.14;
    }

    .introduction {
        max-width: 80rem;
        text-align: center;

        margin-left: auto;
        margin-right: auto;

        font-size: 2.4rem;
        line-height: 1.25;
        color: $charcoal;
    }

    .register-button {
        margin-top: 3.2rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;

        text-align: center;
    }

    .image-container {
        img {
            max-width: 100%;
            height: auto;
            border-radius: 4px;
            box-shadow: 0 14px 28px 0 rgba(0, 0, 0, 0.2);
        }

        &.desktop {
            display: block;
        }

        &.mobile {
            display: none;
        }
    }

    .background-blob {
        position: absolute;
        margin-left: -1700px;
        top: -970px;
        left: 50%;
        z-index: -1;
    }

    @media (max-width: $break_laptop) {
        padding-top: 11rem;

        .PageTitle {
            max-width: 90rem;
            padding-top: 2.2rem;
            padding-bottom: 1.4rem;

            margin-left: auto;
            margin-right: auto;

            font-size: 4.2rem;
            line-height: 1.33;
        }

        .introduction {
            max-width: 70rem;
            font-size: 2.2rem;
        }

        .register-button {
            margin-top: 2.4rem;
            margin-bottom: 3rem;
        }
    }

    @media (max-width: $break_tablet) {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    @media (max-width: $break_mobile) {
        padding-top: 0;

        margin-top: 3.2rem;
        margin-bottom: 0rem;

        margin-left: 1.5rem;
        margin-right: 1.5rem;

        .Breadcrumb {
            justify-content: center;

            .BreadcrumbEntry:first-child {
                display: inline;
            }
        }

        .PageTitle {
            max-width: none;
            padding-top: 3.2rem;
            padding-bottom: 2rem;
            padding-left: 0;
            padding-right: 0;

            font-size: 2.4rem;
            line-height: 1.33;
        }

        .introduction {
            font-size: 2rem;
            line-height: 1.4;
        }

        .register-button {
            margin-top: 2.3rem;

            .Button {
                width: 100%;
            }
        }

        .image-container {
            margin-left: 1.5rem;
            margin-right: 1.5rem;

            margin-bottom: 2rem;

            img {
                box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
            }

            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
            }
        }

        .background-blob {
            display: none;
        }
    }
}
