@import '../../../scss/settings';

.BlogFeaturedSection {
    display: flex;
    margin: 0 15px 20px 15px;

    .featured-post-container {
        padding: 0 30px 25px 0;
        max-width: 1137px;

        .featured-post-link {
            display: block;
            width: 100%;
            text-decoration: none;
            padding-bottom: 20px;

            .featured-post-image {
                display: block;
                width: 100%;
                height: auto;
            }

            .featured-post-title {
                font-family: $font-blog-title;
                font-size: 56px;
                font-weight: 600;
                line-height: 1.07;
                color: $charcoal;
                padding: 31px 0 0 0;

                @media (max-width: $break_desktop) {
                    font-size: 40px;
                }

                @media (max-width: $break_tablet) {
                    font-size: 34px;
                }
            }
        }
    }

    .popular-posts-container {
        display: flex;
        flex-direction: column;
        max-width: calc(33.33% - 20px);

        .popular-posts-heading {
            font-family: $font-blog-body;
            font-size: 16px;
            line-height: 1.25;
            color: $plan-enterprise-color;
            padding: 21px 0 21px 20px;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
        }

        .popular-post-link-container {
            padding: 24px 0 25px 20px;
            border-bottom: 1px solid #e5e5e5;

            .popular-post-title-link {
                display: block;
                text-decoration: none;
                padding-bottom: 17px;

                .popular-post-title {
                    font-family: $font-blog-title;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 1.25;
                    color: $charcoal;

                    @media (max-width: $break_tablet) {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    @media (max-width: $break_mobile) {
        flex-direction: column;
        margin: 0 -20px 36px -20px;

        .featured-post-container {
            padding-right: 0;

            .featured-post-link {
                padding-bottom: 4px;

                .featured-post-title {
                    padding: 12px 20px 0px 20px;
                    font-size: 28px;
                    line-height: 1.25;
                }
            }

            .Tags {
                margin-left: 20px;
            }
        }

        .popular-posts-container {
            max-width: none;

            .popular-posts-heading {
                padding: 15px;
                text-align: center;
            }

            .popular-post-link-container {
                padding: 25px 20px;

                .popular-post-title-link {
                    padding-bottom: 4px;

                    .popular-post-title {
                        font-size: 24px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
