@import '../../../scss/settings';

.PageIntroduction {
    padding: 0 20px 1.5em 20px;

    color: $header-phrase-color;
    font-size: $font-size-3;
    line-height: 1.6;

    &.light {
        color: $white;
    }

    &.center {
        text-align: center;
    }

    @media (max-width: $break_desktop) {
        font-size: $font-size-4;
    }
}
