@import '../../../scss/settings';

.MegaGuidePageHeader {
    padding-top: 16.2rem;
    margin-bottom: 12rem;

    background-color: #f2f2f2;
    background-position: center top;
    background-repeat: no-repeat;

    .Breadcrumb {
        .BreadcrumbEntry {
            a {
                color: #808080;
            }

            &.inactive a {
                color: $charcoal;
            }
        }
    }

    .PageTitle {
        padding-top: 3.2rem;
        padding-bottom: 2.3rem;

        font-size: 5.6rem;
        line-height: 1.14;
    }

    .introduction {
        max-width: 80rem;
        text-align: center;

        margin-left: auto;
        margin-right: auto;

        font-size: 2.4rem;
        line-height: 1.25;
        color: $charcoal;
    }

    .register-button {
        margin-top: 3.2rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;

        text-align: center;
    }

    .image-container {
        img {
            max-width: 100%;
            border-radius: 4px;
            box-shadow: 0 14px 28px 0 rgba(0, 0, 0, 0.2);
        }

        .video {
            position: relative;

            max-width: 100%;
            padding-bottom: calc(9 / 16 * 100%);

            border-radius: 4px;

            background-color: #f2f2f2;

            box-shadow: 0 14px 28px 0 rgba(0, 0, 0, 0.2);

            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &.desktop {
            display: block;
        }

        &.mobile {
            display: none;
        }
    }

    .explanation-container {
        margin-top: 6.6rem;
        margin-left: 13rem;
        margin-right: 13rem;
        padding-bottom: 13rem;
    }

    .guide-explanation {
        margin-bottom: 6rem;

        font-size: 2.2rem;
        line-height: 1.64;
        color: $charcoal;
    }

    .navigation-title {
        margin-bottom: 2.6rem;

        font-size: 2rem;
        font-weight: bold;
        line-height: 1.4;
    }

    .navigation-table {
        background: $white;

        padding: 3rem 4rem;
        margin-left: -5rem;
        margin-right: -5rem;

        border-radius: 8px;

        li {
            list-style: none;
        }

        .navigation-table-entry {
            display: grid;
            grid-template-columns: 30% auto;

            padding: 1rem;

            border-bottom: solid 1px #f2f2f2;

            font-size: 2rem;
            font-weight: bold;
            color: $charcoal;

            text-decoration: none;

            .step-subtitle {
                font-weight: normal;
            }

            &.navigation-table-header {
                font-size: 1.6rem;
                font-weight: normal;
                letter-spacing: -0.1px;
                color: #a3a7ae;
            }
        }
    }

    .background-blob {
        position: absolute;
        margin-left: -1700px;
        top: -970px;
        left: 50%;
        z-index: -1;
    }

    @media (max-width: $break_laptop) {
        padding-top: 11rem;

        .PageTitle {
            max-width: 90rem;
            padding-top: 2.2rem;
            padding-bottom: 1.4rem;

            margin-left: auto;
            margin-right: auto;

            font-size: 4.2rem;
            line-height: 1.33;
        }

        .introduction {
            max-width: 70rem;
            font-size: 2.2rem;
        }

        .register-button {
            margin-top: 2.4rem;
            margin-bottom: 3rem;
        }
    }

    @media (max-width: $break_laptop) {
        background-position: center -140px;
    }

    @media (max-width: $break_tablet) {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    @media (max-width: $break_mobile) {
        padding-top: 0;

        margin-top: 3.2rem;
        margin-bottom: 0rem;

        margin-left: 1.5rem;
        margin-right: 1.5rem;

        background-image: none !important;
        background-color: transparent;

        .Breadcrumb {
            justify-content: center;

            .BreadcrumbEntry:first-child {
                display: inline;
            }
        }

        .PageTitle {
            max-width: none;
            padding-top: 3.2rem;
            padding-bottom: 2rem;
            padding-left: 0;
            padding-right: 0;

            font-size: 2.4rem;
            line-height: 1.33;
        }

        .introduction {
            font-size: 2rem;
            line-height: 1.4;
            text-align: left;
        }

        .register-button {
            margin-top: 2.3rem;

            .Button {
                width: 100%;
            }
        }

        .explanation-container {
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
            padding-bottom: 0;

            .guide-explanation {
                margin-bottom: 2rem;

                font-size: 1.8rem;
                line-height: 1.4;
            }

            .navigation-title {
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }

            .navigation-table {
                margin: 0;
                padding: 0;

                .navigation-table-entry {
                    display: block;

                    padding-left: 0;
                    padding-right: 0;

                    font-size: 1.8rem;

                    > span {
                        display: block;
                    }

                    &.navigation-table-header {
                        display: none;
                    }
                }

                ul > li:first-of-type > .navigation-table-entry {
                    border-top: solid 1px #f2f2f2;
                }
            }
        }

        .image-container {
            margin-left: 1.5rem;
            margin-right: 1.5rem;

            margin-bottom: 2rem;

            img {
                box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
            }

            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
            }
        }

        .background-blob {
            display: none;
        }
    }
}
