@import '../../../../../scss/settings';

.VideoEmbedSlice {
    padding-top: $section_spacing - 42px;
    padding-bottom: 86px;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title-section {
            width: 70%;
            margin-bottom: 20px;

            h2 {
                font-size: 36px;
                padding-bottom: 5px;
            }

            .PageIntroduction {
                font-size: 22px;
                line-height: 1.36;
            }
        }

        .video-container {
            display: flex;

            width: 100%;
            max-width: 1000px;

            .video {
                position: relative;
                display: flex;

                width: 100%;
                padding-bottom: calc((9 / 16) * 100%);

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
    }

    @media (max-width: $break_tablet) {
        padding-top: 40px;
        padding-bottom: 40px;

        &.long {
            padding-bottom: 50px;
        }

        .content {
            .title-section {
                margin-bottom: 0;

                h2 {
                    font-size: $font-size-2;
                }
            }

            .video-container {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        padding-top: 30px;
        padding-bottom: 30px;

        .content {
            .title-section {
                width: auto;
                margin-bottom: 10px;
            }

            .video-container {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}
