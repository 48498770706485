@import '../../../scss/settings';

.BlogLandingPage {
    .PageContainer & {
        max-width: none;
    }

    .content-container {
        padding: 0 90px 0 90px;

        .GridSection {
            .content {
                max-width: none;
            }

            .GridItem {
                margin-bottom: 40px;
            }
        }

        @media (max-width: $break_tablet) {
            padding: 0 50px 50px 50px;

            .title-image-container {
                margin: 0 30px;
            }
        }

        @media (max-width: $break_mobile) {
            padding: 0 20px;

            .title-image-container {
                margin: 0;
            }

            .GridSection {
                margin: 0 -20px;

                .BlogPostLink {
                    margin-bottom: 17.5px;
                }
            }
        }
    }

    .blog-footer-container {
        position: absolute;
        left: 0;
        width: 100%;
    }

    #blog-email-subscription {
        display: none;

        @media (max-width: $break_tablet) {
            display: block;
        }
    }
}
