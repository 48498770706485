@import '../../../scss/settings';

.HeaderMegaMenu {
    position: relative;
    margin-right: 40px;

    .header-menu-button {
        text-decoration: none;
        color: $white;

        background-image: url('./menu-expand-light.svg');
        background-position: center right;
        background-repeat: no-repeat;

        padding-right: 20px;

        .light & {
            color: #6e6e71;
            background-image: url('./menu-expand-dark.svg');
        }

        .white & {
            color: $white;
            background-image: url('./menu-expand-white.svg');
        }
    }

    .header-menu-panel {
        display: none;
        position: absolute;

        top: calc(100% + 5px);
        right: -100px;

        width: 670px;
        padding: 30px;

        border-radius: 6px;

        background: $white;

        box-shadow: 0 11px 15px -7px rgba(50, 59, 74, 0.2), 0 9px 46px 8px rgba(50, 59, 74, 0.12),
            0 24px 38px 3px rgba(50, 59, 74, 0.14);

        z-index: 999999;

        opacity: 0;

        transform: translate(0, -3px);

        transition: opacity 0.1s linear, transform 0.1s ease-in-out;

        &.transition {
            opacity: 1;
            transform: translate(0, 0);
        }

        &.visible {
            display: flex;
        }

        &:before {
            display: block;
            position: absolute;

            width: 10px;
            height: 10px;

            top: -4px;
            right: 101px;

            transform: rotate(45deg);
            background: $white;

            content: '';
        }

        .header-menu-column {
            position: relative;

            flex-basis: 33.3%;

            margin-left: 25px;
            margin-right: 25px;

            &:before {
                display: block;
                position: absolute;

                height: 100%;
                left: -25px;

                border-left: 1px solid #f2f2f2;

                content: '';
            }

            &:first-of-type {
                margin-left: 0;

                &:before {
                    display: none;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }

            .header-column-title {
                color: #6E6E71;
                font-size: 12px;
                font-weight: 600;
                line-height: 1.33;
                text-transform: uppercase;
            }

            .header-column-body {
                font-size: 15px;
                line-height: 1.73;

                h4 {
                    margin-top: 9px;

                    font-size: 16px;
                    color: $charcoal;
                    font-weight: 600;
                }

                li {
                    display: block;
                    margin: 0;
                }

                a {
                    color: #6e6e71;
                    text-decoration: none;

                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }

    @media (max-width: $break_tablet) {
        position: static;
        margin-right: 20px;

        .header-menu-panel {
            top: 65px;
            left: 0;
            right: 0;

            width: calc(100% - 40px);

            margin-left: 20px;
            margin-right: 20px;

            &:before {
                display: none;
            }
        }
    }

    @media (max-width: $break_mobile) {
        position: static;
        margin-right: 0;

        padding-bottom: 20px;
        margin-bottom: 30px;

        border-bottom: 1px solid #f2f2f2;

        .header-menu-button {
            display: block;
            color: $charcoal;
            font-weight: 600;

            background-image: none;
            padding: 0;

            .light & {
                color: $charcoal;
                background-image: none;
            }

            &:after {
                display: none;
            }
        }

        .header-menu-panel {
            display: block;
            position: static;
            top: auto;
            left: auto;
            right: auto;

            width: auto;

            margin: 0;
            padding: 0;

            opacity: 1;

            background: transparent;
            box-shadow: none;

            transform: translate(0, 0);

            &:before {
                display: none;
            }

            &.visible {
                display: block;
            }

            .header-menu-column {
                margin-top: 20px;
                margin-left: 0;
                margin-right: 0;

                &:before {
                    display: none;
                }

                .header-column-body {
                    li {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}
