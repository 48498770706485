@import '../../../scss/settings';

.QuoteSliceSection {
    .Testimonial {
        color: $dark-grey;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        padding-top: 40px;
        padding-bottom: 70px;
    }

    .quote {
        font-size: 30px;
        line-height: 40px;

        max-width: 780px;
    }

    .image {
        margin-bottom: 20px;
    }

    .name,
    .meta {
        font-size: 16px;
        line-height: 20px;
    }

    .meta {
        margin-bottom: 20px;
    }

    // Desktop and smaller
    @media (max-width: $break_desktop) {
        .quote {
            font-size: 24px;
            line-height: 30px;

            max-width: 600px;
        }

        .name,
        .meta {
            font-size: 16px;
        }
    }

    // Tablet and smaller
    @media (max-width: $break_tablet) {
        padding-left: 20px;
        padding-right: 20px;

        .content {
            padding: 40px 0;

            max-width: 707px;
        }
    }
}
