@import '../../../scss/settings';

.Button {
    display: inline-block;
    background: $brand-color;
    border: 0;
    padding: 8px 34px 11px 34px;

    font-family: $font-body;
    font-size: 16px;
    font-weight: 600;
    color: $white;
    text-decoration: none;

    outline: 0;
    cursor: pointer;
    border-radius: 3px;
    border: 0;

    transition: background 0.25s linear;

    -webkit-appearance: none;
    -moz-appearance: none;

    &:disabled {
        background: $mid-light-grey;
    }

    &.secondary {
        background: none;
        border: 1px $secondary-button-border-color solid;
        color: $secondary-button-color;

        &.solid {
            background: $darker-grey;
            border-color: $darker-grey;
            color: $white;
        }
    }

    &.secondary.light {
        border: 1px $white solid;
        color: $white;
    }

    &.no-background {
        background: none;
        color: $brand-color;
        font-weight: normal;

        &:hover {
            text-decoration: underline;
        }

        &.arrow-right {
            &:after {
                position: relative;
                display: inline-block;
                width: 8px;
                height: 13px;

                top: 2px;
                right: auto;

                margin-left: 12px;
                margin-top: 0;

                background-image: url('./icon/arrow-right-red.svg');

                content: '';
            }
        }

        &.arrow-left {
            &:before {
                position: relative;
                display: inline-block;
                width: 8px;
                height: 13px;

                top: 2px;
                right: auto;

                margin-right: 12px;
                margin-top: 0;

                background-image: url('./icon/arrow-left-red.svg');

                content: '';
            }
        }
    }

    &.no-padding {
        padding: 0;
    }
}
