@import '../../../../../scss/settings';

.ColumnFeatureSlice {
    padding-top: 90px;
    padding-bottom: 90px;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .columns {
            display: flex;
            justify-content: space-between;
        }

        .title-section {
            width: 70%;
            margin-bottom: 30px;
        }

        .ColumnFeature {
            h2,
            .feature-info {
                width: 76%;
                max-width: 300px;
            }

            &.column-2 {
                flex-basis: 50%;

                h2,
                .feature-info {
                    width: 90%;
                    max-width: 360px;
                }

                .image-container {
                    width: 72%;
                }
            }

            &.column-3 {
                flex-basis: 390px;
            }

            &.column-4 {
                flex-basis: 293px;

                h2,
                .feature-info {
                    width: 90%;
                    max-width: 300px;
                    font-size: 20px;
                }

                .image-container {
                    width: 90%;
                }
            }
        }
    }

    &.column-section-4,
    &.column-section-2 {
        padding-top: $section_spacing - 40px;
        padding-bottom: $section_spacing - 25px;

        .title-section {
            margin-bottom: 65px;

            h2 {
                font-size: 36px;
                padding-bottom: 5px;
            }

            .PageIntroduction {
                font-size: 22px;
                line-height: 1.36;
            }
        }
    }

    &.column-section-2 {
        padding-top: $section_spacing - 75px;

        .title-section {
            margin-bottom: 20px;
        }
    }

    @media (max-width: $break_tablet) {
        padding-bottom: 50px;

        .content {
            .columns {
                flex-wrap: wrap;
                justify-content: center;
            }

            .ColumnFeature {
                &.column-2,
                &.column-4 {
                    flex-basis: 45%;
                }

                &.column-3 {
                    flex-basis: 50%;
                }

                margin-bottom: 30px;

                h2,
                .feature-info {
                    width: 80%;
                    max-width: 400px;
                }
            }
        }

        &.column-section-4 {
            padding-bottom: 85px;

            .title-section {
                margin-bottom: 80px;

                h2 {
                    font-size: $font-size-2;
                }
            }
        }

        &.column-section-2 {
            padding-bottom: 70px;

            .title-section {
                h2 {
                    font-size: $font-size-2;
                }
            }
        }
    }

    @media (max-width: $break_mobile) {
        margin-top: 0;
        padding-bottom: 0;

        .content {
            .title-section {
                width: auto;
                margin-bottom: 30px;
            }

            .ColumnFeature {
                h2,
                .feature-info {
                    width: 100%;
                    max-width: 100%;

                    padding: 0 30px;
                }

                &.column-2,
                &.column-3,
                &.column-4 {
                    flex-basis: 100%;

                    .image-container {
                        width: 100%;
                    }

                    h2,
                    .feature-info {
                        width: 100%;
                        max-width: 100%;

                        padding: 0 30px;
                    }
                }
            }
        }

        &.column-section-4 {
            padding-top: 30px;
            padding-bottom: 25px;
        }

        &.column-section-2 {
            padding-bottom: 0px;
        }

        &.column-section-4,
        &.column-section-2 {
            .image-container {
                img {
                    max-width: 95%;
                }
            }
        }
    }
}
