@import '../../../scss/settings';

.BlogPaginationControl {
    display: flex;
    justify-content: center;
    font-family: $font-blog-body;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.25;
    color: #f4511c;
    margin: 20px 0 60px;

    .newer-articles {
        padding: 16px 24px;
    }

    .older-articles {
        padding: 16px 24px;

        &.separator {
            border-left: 1px solid #e5e5e5;
        }
    }

    @media (max-width: $break_mobile) {
        font-size: 16px;
        margin: 0 0 25px 0;
    }
}
