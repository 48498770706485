@import 'settings';

#onetrust-consent-sdk {
    /*
   * Cookie banner
   */
    #onetrust-banner-sdk.otFlat {
        width: 90%;
        max-width: 1050px;

        border-radius: 8px;
        box-shadow: 0 10px 20px 3px rgba(50, 59, 74, 0.2);

        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;

        opacity: 1;
        animation: fade-in 0.15s linear, slide-up 0.15s ease-out;
        transition: none !important;

        * {
            font-size: 16px;
        }

        &:focus {
            outline: none;
        }

        button {
            font-size: 16px;
            font-weight: normal;
            padding: 10px 20px 11px 20px;
            white-space: nowrap;
        }

        button:hover {
            opacity: 1 !important;
        }

        > div {
            display: flex;
            align-items: flex-start;

            // Cookie Policy container
            .ot-sdk-container {
                width: auto;
                flex-shrink: 100;
                padding: 0;

                .ot-sdk-row {
                    display: flex;
                    align-items: center;

                    @media (max-width: $break_mobile) {
                        flex-direction: column;
                    }

                    // Policy text
                    #onetrust-group-container {
                        width: auto;
                        flex-shrink: 100;

                        #onetrust-policy {
                            margin: 32px;

                            @media (max-width: $break_mobile) {
                                margin: 24px;
                            }

                            #onetrust-policy-text {
                                margin: 0;

                                a,
                                a:hover,
                                a:visited {
                                    text-decoration: underline;
                                    outline: none;
                                }
                            }
                        }
                    }

                    // Consent buttons
                    #onetrust-button-group-parent {
                        width: auto;
                        position: initial;
                        transform: initial;
                        padding-right: 32px;
                        padding-left: 0;

                        @media (max-width: $break_mobile) {
                            width: 100%;
                            padding: 0 24px;
                        }

                        #onetrust-button-group {
                            all: unset;
                            display: flex;
                            align-items: stretch;

                            @media (max-width: $break_mobile) {
                                flex-direction: column-reverse;
                            }

                            .cookie-setting-link {
                                font-weight: normal;
                                white-space: nowrap;
                            }

                            button {
                                width: auto;
                                text-align: center;
                                margin-top: 2px;
                                margin-bottom: 2px;
                                border-radius: 3px;

                                @media (max-width: $break_mobile) {
                                    margin-bottom: 7px;
                                    margin-right: 0;

                                    &:first-child {
                                        margin-bottom: 20px;
                                    }
                                }
                            }

                            button:hover {
                                opacity: 1;
                            }

                            button:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            // Close button
            #onetrust-close-btn-container {
                width: auto;
                position: absolute;
                transform: initial;
                margin: 0;
                top: 0;
                right: 0;

                button {
                    padding: 20px;
                    top: 0;
                    right: 0;
                }

                button:hover {
                    opacity: 1;
                }
            }
        }

        // Mobile only
        @media (max-width: $break_mobile) {
            width: 95%;
            margin-bottom: 10px;
        }
    }

    /*
   * Cookie preference centre
   */
    #onetrust-pc-sdk.ot-fade-in,
    .onetrust-pc-dark-filter.ot-fade-in {
        animation: none !important;
        transition: none !important;
    }
    #onetrust-pc-sdk.otPcCenter {
        border-radius: 8px;
        height: 70vh !important;
        top: 15%;

        @media (max-width: $break_mobile) {
            border-radius: 0;
            height: 100vh !important;
            top: 0;
        }

        & :focus,
        & :hover {
            outline: none;
        }

        h2,
        h3 {
            font-size: 18px;
        }

        button {
            font-size: 16px;
            font-weight: normal;
        }

        button.save-preference-btn-handler,
        button.ot-pc-refuse-all-handler,
        button#accept-recommended-btn-handler {
            padding: 10px 20px 11px 20px;
        }

        .ot-pc-header {
            height: 0;
            padding: 0;
            border-bottom: none;

            .ot-pc-logo {
                display: none;
            }
        }

        #ot-pc-content {
            top: 23px;
            bottom: 91px;
            margin: 0;
            padding: 0 32px;
            width: auto;

            @media (max-width: $break_mobile) {
                bottom: 172px;
            }

            button:hover {
                opacity: 1;
            }

            #ot-pc-desc {
                font-size: 16px;
                line-height: 23px;
            }

            .ot-cat-grp {
                padding-bottom: 50px;

                .ot-cat-item.ot-accordion-layout {
                    border: none;

                    &:first-of-type {
                        border-top: none;
                    }

                    .ot-acc-hdr {
                        display: flex;
                        align-items: center;
                        padding: 7.5px 0;
                        width: 100%;

                        .ot-cat-header {
                            flex-grow: 1;
                            font-size: 16px;
                            font-weight: normal;
                            margin-left: 25px;
                        }

                        .ot-always-active {
                            color: #4eb85a;
                            right: 0;
                            font-weight: normal;
                        }

                        // Arrow
                        .ot-arw-cntr {
                            display: none;
                        }

                        // Toggle
                        .ot-tgl {
                            position: static;
                            transform: initial;
                            top: auto;
                            right: auto;

                            input + .ot-switch .ot-switch-nob {
                                background-color: #e8e9eb;
                                border: 1px solid #e8e9eb;

                                &::before {
                                    background-color: #fff;
                                }
                            }

                            input:checked + .ot-switch .ot-switch-nob {
                                background-color: #4eb85a;
                                border: 1px solid #4eb85a;

                                &::before {
                                    background-color: #fff;
                                }
                            }

                            input:focus + .ot-switch {
                                outline: none;
                            }
                        }
                    }

                    .ot-acc-hdr .ot-plus-minus {
                        background-image: url('./img/arrow-small-right.svg');
                        background-size: contain;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        width: 20px;
                        height: 20px;
                        margin-left: -6px;

                        span {
                            display: none;
                        }
                    }

                    [aria-expanded='true'] + .ot-acc-hdr .ot-plus-minus {
                        -webkit-transform: translateY(-50%) rotate(90deg);
                        transform: translateY(-50%) rotate(90deg);
                    }

                    .ot-acc-txt {
                        margin: 11.5px 0;
                        background-color: #fff;
                        padding: 0 0 0 25px;
                        width: auto;

                        .ot-category-desc {
                            padding: 0;
                            margin-top: 0;
                            line-height: 22px;
                            font-size: 14px;
                            color: #323b4a;
                            width: auto;
                        }

                        .ot-hlst-cntr {
                            padding: 0;

                            button {
                                font-size: 14px;
                                text-decoration: underline;
                            }
                        }

                        .ot-subgrp-cntr {
                            border: none;
                            padding-left: 0;
                            padding-right: 0;
                            width: auto;

                            .ot-subgrp {
                                margin: 0;

                                h5 {
                                    font-size: 16px;
                                }

                                .ot-subgrp-desc {
                                    font-size: 14px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }

                    .ot-cat-grp {
                        .ot-always-active {
                            color: #4eb85a;
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .ot-pc-footer {
            border-top: none;
            position: absolute;
            right: 0;
            bottom: 0;
            background: white;
            z-index: 100;
            width: 100%;
            margin-bottom: 12px;

            .ot-btn-container {
                margin: 0 20px;
                border-top: solid 1px #e8e9eb;

                button {
                    width: auto;
                    margin: 20px 0;
                    border-radius: 3px;
                    letter-spacing: normal;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                button:hover {
                    opacity: 1;
                }

                @media (max-width: $break_mobile) {
                    width: calc(100% - 40px);
                    padding: 20px 0 0 0;

                    button {
                        width: 100%;
                        margin: 10px 0 0 0;
                    }
                }
            }
        }

        .ot-pc-footer-logo {
            position: absolute;
            bottom: 0;
            height: 100%;
            display: flex;
            background: transparent;
            text-align: left;
            z-index: -1;

            a {
                width: 120px;

                margin: auto 0 auto 20px;
            }

            @media (max-width: $break_mobile) {
                position: static;
                height: auto;
                display: block;
                text-align: center;
                margin-top: 20px;
            }
        }
    }

    /*
   * Cookie List
   */
    #ot-pc-lst.ot-hosts-ui {
        top: 23px;
        bottom: 91px;
        overflow: visible;

        @media (max-width: $break_mobile) {
            bottom: 172px;
        }

        #ot-pc-hdr {
            margin: 0 20px;
            width: auto;

            .ot-lst-title {
                font-size: 18px;
            }

            .ot-search-cntr {
                width: 90%;

                svg {
                    right: 0;
                }
            }

            .ot-fltr-cntr {
                right: 0;
            }

            .ot-lst-subhdr {
                margin: 20px 0;

                button#filter-btn-handler {
                    background-color: #323b4a;
                    border: none;
                }

                button#filter-btn-handler:hover,
                button#filter-btn-handler:focus {
                    opacity: 1;
                }

                #ot-anchor {
                    right: 24px;
                    top: 102px;
                }

                #ot-fltr-cnt {
                    left: auto;
                    right: 15px;
                    margin-top: 15px;
                    height: auto;
                    width: auto;
                    padding: 20px;

                    @media only screen and (max-width: 600px) {
                        top: 99px;
                    }

                    #clear-filters-handler {
                        margin-top: 0;
                        margin-bottom: 25px;
                    }

                    .ot-fltr-scrlcnt {
                        overflow-x: initial;
                        overflow-y: initial;

                        .ot-fltr-opt {
                            margin-left: 0;
                            width: auto;

                            .ot-chkbox input[type='checkbox']:focus {
                                outline: none;
                            }
                        }

                        .ot-fltr-btns {
                            margin-left: 0;

                            @media only screen and (max-width: 600px) {
                                padding-top: 20px;
                            }

                            button {
                                margin-bottom: 0;
                            }

                            button:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            #vendor-search-handler {
                margin-left: 0;
            }

            #ot-back-arw {
                margin: 0;
            }
        }

        #ot-lst-cnt {
            position: absolute;
            top: 120px;
            right: 0;
            bottom: 0;
            left: 0;

            min-width: auto;
            width: auto;
            height: auto;
            max-height: initial;
            margin: 0;
            padding: 0 20px;

            #ot-host-lst {
                .ot-host-item {
                    padding: 15px 0;

                    .ot-acc-txt {
                        width: auto;

                        .ot-host-opt li > div div {
                            font-size: 12px;
                        }
                    }

                    button:focus {
                        border: none;
                    }

                    // Cookie name
                    .ot-acc-hdr {
                        display: flex;
                        position: relative;

                        .ot-host-hdr {
                            margin-left: 30px;
                        }
                    }

                    .ot-acc-hdr .ot-plus-minus {
                        background-image: url('./img/arrow-small-right.svg');
                        background-size: contain;
                        position: absolute;
                        top: 30%;
                        left: 0;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        width: 20px;
                        height: 20px;

                        span {
                            display: none;
                        }
                    }

                    [aria-expanded='true'] + .ot-acc-hdr .ot-plus-minus {
                        -webkit-transform: translateY(-50%) rotate(90deg);
                        transform: translateY(-50%) rotate(90deg);
                    }

                    .ot-c-description {
                        div:last-child {
                            width: 65%;
                        }
                    }
                }
            }
        }
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(40px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
