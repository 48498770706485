@import '../../../scss/settings';

.HeaderLogo {
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        text-decoration: none;
    }

    .LoginButton {
        margin-left: auto;
    }

    .mobile {
        display: none;
    }

    @media (max-width: $break_mobile) {
        .MilanoteLogo.desktop {
            display: none;
        }

        .MilanoteLogo.mobile {
            display: flex;
        }
    }
}
