@import '../../../../../scss/settings';

.GuideExampleSlice {
    margin-top: 2rem;

    font-size: 2rem;
    line-height: 1.4;
    color: $charcoal;

    .guide-example-title {
        padding-bottom: 1rem;
        font-weight: bold;
    }

    .guide-example-steps {
        list-style: decimal;
        padding-left: 2rem;
        padding-right: 1rem;
    }

    .guide-example-step {
        margin-bottom: 0.5rem;

        .step-text {
            margin-left: 1.5rem;
            margin-right: 1rem;

            p {
                display: inline;
            }
        }

        .guide-example-show-tooltip-button {
            display: inline-flex;
            border: 0;

            color: $brand-color;
            font-size: 2rem;
            font-family: $font-body;

            background: none;

            cursor: pointer;

            outline: none;

            .button-text {
                margin-right: 0.8rem;
            }

            .expand-plus {
                position: relative;

                display: block;
                width: 20px;
                height: 21px;

                top: 2px;

                background: url('./tooltip-button-plus.svg');

                transition: transform 0.15s ease-in-out;
            }

            &.open {
                .expand-plus {
                    transform: rotate(45deg);
                }
            }
        }
    }

    .guide-example-tooltip-content {
        display: none;
    }

    @media (max-width: $break_laptop) {
        font-size: 1.8rem;

        .guide-example-title {
            padding-bottom: 0.6rem;
        }

        .guide-example-step {
            margin-bottom: 0.3rem;

            .guide-example-show-tooltip-button {
                font-size: 1.8rem;
            }
        }
    }

    @media (max-width: $break_mobile) {
        padding-left: 15px;
        padding-right: 15px;

        font-size: 1.8rem;

        .guide-example-step {
            .step-text {
                margin-left: 0;
            }

            .guide-example-show-tooltip-button {
                display: none;
            }
        }
    }
}
