@use 'sass:math';
@import '../../../../../scss/settings';

.FeatureSectionSlice.AsideSection {
    position: relative;
    overflow: hidden;
    padding: 0 30px;

    h2 {
        font-size: $font-size-3;
        font-family: $font-title;
        font-weight: $font-weight-semibold;

        margin-bottom: 22px;
        color: $charcoal;
    }

    .body {
        text-align: left;
    }

    .summary {
        font-size: $font-size-4;
        line-height: 27px;
        margin-bottom: 17px;
        color: $dark-grey;

        p {
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .details {
        font-size: $font-size-5;
        line-height: $font-size-3;
        color: $dark-grey;

        ul {
            padding-left: 17px;
            list-style: disc;

            li {
                margin-bottom: 15px;
            }
        }
    }

    .link {
        margin-top: 30px;
        font-size: $font-size-5;

        a {
            text-decoration: none;
            font-weight: $font-weight-semibold;
        }
    }

    .image-spacer {
        width: 100%;
    }

    .image-container {
        position: relative;
    }

    .image-positioner {
        img {
            width: 100%;
            height: auto;
        }
    }

    .store-link {
        margin-top: 30px;
    }

    .reviews {
        margin-top: 40px;

        .review {
            padding: 30px 30px 40px 30px;
        }

        .review,
        .review-stack {
            background: $white;
            box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.25), 0 2px 8px 0 rgba(0, 0, 0, 0.15);
        }

        .review-stack {
            height: 7px;
            margin: 0 auto;
        }

        .review-stack-back {
            width: 93%;
        }

        .review-stack-middle {
            width: 97%;
        }

        .review-header {
            display: flex;
        }

        .review-rating {
            font-size: 14px;
            margin-left: 20px;
        }

        .review-author {
            color: $brand-purple-color;
            font-size: 14px;
            margin-bottom: 10px;
        }

        .review-text {
            color: $darker-grey;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
        }
    }

    // Desktop only
    @media (min-width: $break_desktop) {
        &.no-margin.left {
            .section-content {
                margin-left: 17%;
                margin-right: 14px;
            }
        }

        &.no-margin.right {
            .section-content {
                margin-left: 14px;
                margin-right: 17%;
            }
        }
    }

    // Tablet only
    @media (min-width: $break_tablet_portrait + 1) and (max-width: $break_tablet) {
        &.left {
            > .content {
                flex-direction: row;
            }
        }

        &.right {
            > .content {
                flex-direction: row-reverse;
            }
        }

        .content {
            section {
                padding: 0;

                .section-content {
                    padding: 0;
                    margin: 0;

                    h2 {
                        font-size: 22px;
                    }

                    .summary {
                        font-size: 18px;
                    }

                    .body {
                        margin: 0;
                    }
                }
            }
        }
    }

    // Desktop and tablet
    @media (min-width: $break_tablet_portrait + 1) {
        > .content {
            position: relative;

            // This is used to show the "discs" in the unordered lists
            overflow: visible;

            > section {
                flex-basis: 570px;
                flex-shrink: 1;

                margin-top: 70px;
                margin-bottom: 70px;

                .body {
                    position: relative;
                    z-index: 1;
                }
            }

            > aside {
                padding: 0;
                width: 100%;
                flex-basis: 600px;
                height: auto;
            }
        }

        .image-positioner {
            position: absolute;
            display: flex;
            align-items: center;

            width: percentage(math.div(735, 1170));
            height: 100%;
            top: 0;
            bottom: 0;
        }

        &.no-margin.left,
        &.no-margin.right {
            > .content {
                margin-right: auto;
                margin-left: auto;
            }
        }

        &.no-margin.left {
            .image-positioner {
                left: 0;

                img {
                    transform: translate3d(-135px, 0, 0);
                }
            }
        }

        &.no-margin.right {
            .image-positioner {
                right: 0;

                img {
                    transform: translate3d(135px, 0, 0);
                }
            }
        }
    }

    @media (max-width: $break_tablet_portrait) {
        padding: 0;

        .content {
            section {
                padding: 0;

                .section-content {
                    padding: 30px 0 50px 0;
                    max-width: none;

                    .body {
                        margin: 0 30px;
                        text-align: left;
                    }
                }
            }

            aside {
                display: none;
            }
        }

        .image-positioner {
            width: 100%;
            margin-bottom: 30px;

            img {
                max-width: 735px;
                width: 100%;
            }
        }

        &.left .content section,
        &.right .content section {
            margin-left: 0;
            margin-right: 0;
        }

        &.right {
            .image-positioner {
                text-align: right;
            }
        }

        &.left {
            .image-positioner {
                text-align: left;
            }
        }

        .image-spacer {
            display: none;
        }
    }
}
