@import '../../../../../scss/settings';

.InformationSectionSlice {
    position: relative;
    background: $light-grey;
    z-index: 1;

    .overlay {
        padding: 24px 30px 30px 150px;

        background: $brand-purple-color;
        color: $white;
    }

    .info-icon {
        position: absolute;

        width: 34px;
        height: 34px;
    }

    .title {
        display: flex;
        align-items: center;
        height: 34px;

        font-weight: bold;
    }

    .description {
        line-height: 24px;

        p {
            margin-top: 10px;
        }

        a {
            color: $white;
        }
    }

    @media (min-width: $break_tablet + 1) {
        margin-bottom: -85px;

        .overlay {
            position: relative;
            width: 802px;
            max-width: 95%;

            padding-left: 150px;

            box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.15);
            transform: translate3d(0, -90px, 0);
        }

        .info-icon {
            left: 96px;
        }
    }

    @media (max-width: $break_tablet) {
        display: none;
    }
}
