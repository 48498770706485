@import '../../../../../scss/settings';

.LargeImageSlice {
    padding-top: $section_spacing - 42px;

    &.long {
        padding-bottom: 86px;
    }

    &.padding-bottom {
        padding-bottom: 95px;
    }

    &.extra-large {
        .content .image-container {
            padding-left: 0;
            padding-right: 0;

            img {
                max-width: 1140px;
            }

            @media (max-width: $break_laptop) {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title-section {
            width: 70%;
            margin-bottom: 20px;

            h2 {
                font-size: 36px;
                padding-bottom: 5px;
            }

            .PageIntroduction {
                font-size: 22px;
                line-height: 1.36;
            }
        }

        .image-container {
            padding-left: 40px;
            padding-right: 40px;

            video,
            img {
                width: 100%;
                max-width: 1000px;
                height: auto;

                border-radius: 6px;
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2), 0 10px 30px rgba(0, 0, 0, 0.2);
            }
        }
    }

    @media (max-width: $break_tablet) {
        padding-top: 40px;
        padding-bottom: 0;

        &.long {
            padding-bottom: 50px;
        }

        .content {
            .title-section {
                margin-bottom: 0;

                h2 {
                    font-size: $font-size-2;
                }
            }

            .image-container {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    @media (max-width: $break_mobile) {
        padding-top: 30px;
        padding-bottom: 0;

        .content {
            .image-container {
                padding-left: 20px;
                padding-right: 20px;
            }

            .title-section {
                width: auto;
                margin-bottom: 10px;
            }
        }
    }
}
