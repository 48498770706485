@import '../../../scss/settings';

.BlogTitle {
    padding: 91px 0 75px 0;

    .title-image-container {
        margin: 0 auto;
        max-width: 805px;
        border-bottom: 1px solid rgba(207, 205, 210, 0.5);

        .title-image-link {
            display: block;
            background-image: url('./blog-landing-page-title.png');
            width: 100%;
            padding-bottom: 11.5%;
            background-size: 100% auto;
            background-repeat: no-repeat;
            margin-bottom: 15px;
        }

        .title-text {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
    }

    .title-description {
        display: flex;
        justify-content: center;
        text-align: center;
        padding-top: 17px;
        color: $plan-enterprise-color;
        font-style: italic;
        font-size: 16px;
        line-height: 1.25;
        font-family: $font-blog-body;
        font-weight: normal;

        p {
            margin-right: 5px;
        }
    }

    @media (max-width: $break_tablet) {
        padding: 65px 0 75px 0;
    }

    @media (max-width: $break_mobile) {
        padding: 24px 0;

        .title-image-container {
            margin: 0 auto;
            padding: 0;

            .title-image-link {
                background-image: url('./blog-landing-page-title.png');
            }
        }

        .title-description {
            font-size: 14px;
            line-height: 1.43;
            padding: 15px 0 0;
        }
    }

    @media (max-width: 525px) {
        .title-description {
            flex-direction: column;
        }
    }
}
