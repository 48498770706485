@import '../../../../../scss/settings';

.Section.GuideSummarySlice {
    margin-bottom: 30px;

    .PageTitle {
        font-size: $font-size-2;
        margin-bottom: 20px;
    }

    .PageIntroduction {
        max-width: 790px;
        margin: 0 auto;

        text-align: left;
        font-size: $font-size-4;

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-top: 44px;
            margin-bottom: 20px;

            color: $charcoal;
            font-family: $font-title;
            font-weight: $font-weight-semibold;
            font-size: $font-size-3;
        }

        iframe,
        img {
            max-width: 100%;
            margin: 10px auto;
        }

        ol,
        ul,
        p,
        ol li,
        ul li {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ol,
        li {
            padding-left: 1em;

            li {
                margin-left: 8px;
                padding-left: 0.8em;
            }
        }

        ol {
            list-style: decimal;
        }

        ul {
            list-style: circle;
        }

        a {
            text-decoration: none;
        }
    }

    @media (max-width: $break_mobile) {
        margin-bottom: 0;

        .PageTitle {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 30px;

            font-size: 20px;
        }

        .PageIntroduction {
            font-size: 18px;

            h1,
            h2,
            h3,
            h4,
            h5 {
                margin-bottom: 20px;
                font-size: 18px;
                font-family: $font-body;
            }
        }
    }
}
