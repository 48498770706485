@import '../../../scss/settings';

.BlogSubscribeSuccess {
    padding-top: 100px;

    .success-subscription-container {
        position: absolute;
        width: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        background-color: $light-grey;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        min-height: 200px;

        .title-container {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .success-subscription-tick {
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }

            .success-subscription-title {
                font-family: $font-blog-title;
                font-size: 56px;
                font-weight: 600;
                line-height: 1.14;
                color: $charcoal;
            }
        }

        .rss-json-description {
            font-family: $font-blog-body;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.56;
            text-align: center;
            color: #b2b2b2;

            a {
                color: #b2b2b2;
            }
        }
    }

    .BlogPostBackLinks {
        padding-top: 268px;

        .Section {
            margin-bottom: 75px;
        }
    }

    .back-link {
        display: block;
        font-family: $font-blog-body;
        font-size: 20px;
        font-weight: normal;
        line-height: 1.25;
        text-align: center;
        color: $brand-color;
        margin-bottom: 78px;
        text-decoration: underline;
    }
}
