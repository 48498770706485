@import '../../../../../scss/settings';

.GuideTipSlice {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 110px auto;

    margin-top: 2.9rem;
    padding: 2.8rem 3.4rem;

    border-radius: 8px;

    font-size: 1.8rem;
    color: #6e6e71;

    background-color: #f2f2f2;
    background-image: url('https://images.milanote.com/milanote/6a306d8b-46fc-4301-91a3-d48700094f97_guide-tip-background-light.png?auto=compress,format&w=800');
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;

    .tip-title {
        font-weight: bold;
    }

    @media (max-width: $break_laptop) {
        padding: 1.8rem 2.2rem;
        font-size: 1.6rem;
    }

    @media (max-width: $break_mobile) {
        display: block;
        margin: 3.2rem 1.5rem 0 1.5rem;
        padding: 1.8rem 2rem 3rem 2rem;

        font-size: 1.6rem;

        background-size: auto 70%;
        background-position: center bottom;

        .tip-title {
            margin-bottom: 1rem;
        }
    }
}
